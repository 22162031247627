import imageCompression from "browser-image-compression";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { SCREENS } from "../../../lib/route/utils/router";
import { COMPRESSIONS_OPTIONS, PHONE_CODE } from "../../../utils/constants";

import {
  Gender,
  GetMyUserProfileDocument,
  UserInput,
  UserType,
  useGetMyUserProfileQuery,
  useUpdateMyUserProfileMutation,
} from "../../../lib/apollo/graphql/generated";
import { firebaseSignOut } from "../../../lib/firebase";
import { useApolloClient } from "@apollo/client";

interface State {
  avatarState: any;
  name: string;
  gender: Gender | null;
  phoneCode: { label: string; value: string };
  phone: string;
  dateOfBirth: string;
  nationality: null | { label: string; value: string };
  languageOptionIds: string[];
}

function usePersonalProfileForm(isCreating: boolean) {
  const navigate = useNavigate();
  const params = useParams();
  const client = useApolloClient();

  const avatarInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<State>({
    avatarState: null,
    name: "",
    gender: null,
    phoneCode: {
      label: PHONE_CODE[0].country + `(${PHONE_CODE[0].code})`,
      value: PHONE_CODE[0].code,
    },
    phone: "",
    dateOfBirth: dayjs(new Date(2000, 1, 1)).format("YYYY-MM-DD"),
    nationality: null,
    languageOptionIds: [],
  });

  const { loading, data } = useGetMyUserProfileQuery({
    onCompleted: () => {
      if (data?.getMyUserProfile) {
        const {
          name,
          gender,
          phone,
          dateOfBirth,
          nationality,
          languageOptions,
          phoneCode,
        } = data?.getMyUserProfile;

        let phoneCodeValue = {
          label: PHONE_CODE[0].country + `(${PHONE_CODE[0].code})`,
          value: PHONE_CODE[0].code,
        };

        if (phoneCode) {
          const currentPhoneCode = PHONE_CODE.find(
            (code) => code.code === phoneCode
          );
          if (currentPhoneCode) {
            phoneCodeValue = {
              label: currentPhoneCode!.country + `(${currentPhoneCode!.code})`,
              value: currentPhoneCode!.code,
            };
          }
        }

        setState((prev) => ({
          ...prev,
          name: name || "",
          gender: gender || null,
          phone: phone || "",
          dateOfBirth: dateOfBirth
            ? dayjs(dateOfBirth).format("YYYY-MM-DD")
            : prev.dateOfBirth,
          nationality: nationality
            ? { label: nationality?.name, value: nationality?.id }
            : null,
          languageOptionIds:
            !!languageOptions && languageOptions?.length > 0
              ? languageOptions?.map((option) => option?.id!)
              : [],
          phoneCode: phoneCodeValue,
        }));
      }
    },
  });

  const [updateMyUserProfile, { loading: updateLoading }] =
    useUpdateMyUserProfileMutation({
      onCompleted: (data) => {
        if (data?.updateMyUserProfile) {
          if (isCreating) {
            navigate(SCREENS.SIGN_UP + "/step3");
          }
        }
      },
      refetchQueries: isCreating
        ? () => [
            {
              query: GetMyUserProfileDocument,
            },
          ]
        : undefined,
    });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: key === "phone" ? value.replace("-", "") : value,
    }));
  }

  function onGenderSelect(gender: Gender) {
    setState((prev) => ({
      ...prev,
      gender,
    }));
  }

  function onAvatarChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e?.target;

    if (!files) return;

    setState((prev) => ({
      ...prev,
      avatarState: files?.[0],
    }));
  }

  function onNationalityChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      nationality: newValue,
    }));
  }

  function onPhoneCodeChange(
    newValue: SingleValue<{ label: string; value: string }>
  ) {
    setState((prev) => ({
      ...prev,
      phoneCode: newValue!,
    }));
  }

  function onLanguageOptionSelect(optionId: string) {
    if (state?.languageOptionIds?.length > 3) return;

    const languageOptionSet = new Set([...state?.languageOptionIds]);

    if (languageOptionSet.has(optionId)) {
      languageOptionSet.delete(optionId);
    } else {
      languageOptionSet.add(optionId);
    }

    const editedLanguageOptionIds = Array.from(languageOptionSet);

    setState((prev) => ({
      ...prev,
      languageOptionIds: editedLanguageOptionIds,
    }));
  }

  function isSubmitDisabled() {
    const { name, gender, phoneCode, phone, nationality, languageOptionIds } =
      state;

    return (
      !name ||
      !gender ||
      !phoneCode ||
      !phone ||
      !nationality ||
      !(languageOptionIds?.length > 0) ||
      loading ||
      updateLoading
    );
  }

  async function onSubmit() {
    const {
      name,
      gender,
      phoneCode,
      phone,
      dateOfBirth,
      avatarState,
      nationality,
      languageOptionIds,
    } = state;

    const userInput: UserInput = {
      name,
      gender,
      phoneCode: phoneCode?.value,
      phone,
      dateOfBirth,
      type: UserType.Personal,
      nationalityId: nationality?.value,
      languageOptionIds,
    };

    if (avatarState) {
      const compressedFile = await imageCompression(
        avatarState,
        COMPRESSIONS_OPTIONS
      );

      userInput.avatarInput = { file: compressedFile };
    }

    updateMyUserProfile({
      variables: {
        userInput,
      },
    });
  }

  async function onSignOut() {
    await firebaseSignOut();
    await client.clearStore();
    navigate(SCREENS.HOME);
  }

  return {
    refs: {
      avatarInputRef,
    },
    models: {
      state,
      loading,
      updateLoading,
      data: data?.getMyUserProfile,
    },
    operations: {
      onInputChange,
      onAvatarChange,
      onGenderSelect,
      onNationalityChange,
      onLanguageOptionSelect,
      onPhoneCodeChange,
      isSubmitDisabled,
      onSubmit,
      onSignOut,
    },
  };
}

export default usePersonalProfileForm;
