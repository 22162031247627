import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SCREENS } from "../../../../lib/route/utils/router";
import theme from "../../../../lib/theme";
import Flex from "../../../common/components/Flex";
import Label from "../../../common/components/Label";
import { useScreenWidthContext } from "../../../common/provider/ScreenWidthProvider";

function MyPageNavigationBar() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const { pathname } = useLocation();

  const menu = useMemo(
    () => [
      {
        title: t("myPage.userProfile"),
        path: SCREENS.MY_USER_PROFILE,
      },
      {
        title: t("myPage.profile"),
        path: SCREENS.MY_PROFILE,
      },
      {
        title: t("myPage.passHistory"),
        path: SCREENS.MY_PASSES,
      },
      {
        title: t("myPage.subscriptionHistory"),
        path: SCREENS.MY_SUBSCRIPTION,
      },
      {
        title: t("myPage.billings"),
        path: SCREENS.MY_BILLINGS,
      },
      {
        title: t("myPage.sessions"),
        path: SCREENS.MY_SESSION_HISTORY,
      },
    ],
    []
  );

  return (
    <Flex
      width="100%"
      flexDirection={isMobile ? "column" : "row"}
      alignItems={isMobile ? "flex-start" : "center"}
      justifyContent="space-between"
      backgroundColor={isMobile ? "white" : theme.color.secondary3}
      borderRadius={isMobile ? "none" : 40}
      paddingLeft={isMobile ? 0 : theme.spacing[24]}
      paddingRight={isMobile ? 0 : theme.spacing[24]}
    >
      {menu?.map((item) => (
        <Label
          key={item?.title}
          text={item?.title}
          current={pathname?.includes(item?.path)}
          size="lg"
          onClick={() => navigate(SCREENS.MY_PAGE + item?.path)}
        />
      ))}
    </Flex>
  );
}

export default MyPageNavigationBar;
