import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";

import {
  GetMyInsurancesDocument,
  UserSurgeryInput,
  useDeleteMyInsuranceMutation,
  useGetVaccineTypesQuery,
  useUpdateMyInsuranceMutation,
} from "../../../../lib/apollo/graphql/generated";
import { InsuranceFormProps } from "./InsuranceForm";
import { useRef, useState } from "react";

function useInsuranceForm({ insurance }: InsuranceFormProps) {
  const { i18n } = useTranslation();

  const mediaInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    id: insurance?.id || null,
    type: insurance?.type || "",
    name: insurance?.name || "",
    coverage: insurance?.coverage || "",
    memberId: insurance?.memberId || "",
    groupId: insurance?.groupId || "",
    companyNumber: insurance?.companyNumber || "",
  });

  const [updateMyInsurance, { loading: updateLoading }] =
    useUpdateMyInsuranceMutation({
      onCompleted: () => {
        setState({
          id: insurance?.id || null,
          type: insurance?.type || "",
          name: insurance?.name || "",
          coverage: insurance?.coverage || "",
          memberId: insurance?.memberId || "",
          groupId: insurance?.groupId || "",
          companyNumber: insurance?.companyNumber || "",
        });
      },
      refetchQueries: !insurance
        ? () => [
            {
              query: GetMyInsurancesDocument,
            },
          ]
        : undefined,
    });
  const [deleteMyInsurance] = useDeleteMyInsuranceMutation({
    refetchQueries: () => [
      {
        query: GetMyInsurancesDocument,
      },
    ],
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onMediaChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (!files) return;

    const file = files?.[0];

    setState((prev: any) => ({
      ...prev,
      mediaState: file,
    }));
  }

  function onDeleteClick() {
    deleteMyInsurance({
      variables: {
        userInsuranceId: insurance?.id!,
      },
    });
  }

  function isSubmitDisabled() {
    const { type, name, coverage } = state;

    return !type || !coverage || !name || updateLoading;
  }

  async function onAddClick() {
    if (isSubmitDisabled()) return;

    const { type, name, coverage, memberId, groupId, companyNumber } = state;

    updateMyInsurance({
      variables: {
        userInsuranceInput: {
          id: insurance?.id,
          type,
          name,
          coverage,
          memberId,
          groupId,
          companyNumber,
        },
      },
    });
  }

  return {
    refs: {
      mediaInputRef,
    },
    models: {
      state,
    },
    operations: {
      onInputChange,
      onMediaChange,
      onDeleteClick,
      onAddClick,
    },
  };
}

export default useInsuranceForm;
