import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AllergySymptom = {
  __typename?: "AllergySymptom";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type AllergyType = {
  __typename?: "AllergyType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type BankAccount = {
  __typename?: "BankAccount";
  accountNumber: Scalars["String"]["output"];
  bankName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  user: User;
};

export type BankAccountInput = {
  accountNumber: Scalars["String"]["input"];
  bankName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Billing = {
  __typename?: "Billing";
  active: Scalars["Boolean"]["output"];
  cardName?: Maybe<Scalars["String"]["output"]>;
  cardNumber?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customerUid: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  pgProvider?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type BillingInput = {
  customerUid: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum BloodType {
  A = "A",
  Ab = "AB",
  B = "B",
  O = "O",
}

export type ChronicDiseaseType = {
  __typename?: "ChronicDiseaseType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Education = {
  __typename?: "Education";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  major?: Maybe<Scalars["String"]["output"]>;
  school?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type EducationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  major?: InputMaybe<Scalars["String"]["input"]>;
  school?: InputMaybe<Scalars["String"]["input"]>;
};

export type Experience = {
  __typename?: "Experience";
  createdAt: Scalars["DateTime"]["output"];
  duration?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  job?: Maybe<Scalars["String"]["output"]>;
  place?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExperienceInput = {
  duration?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  place?: InputMaybe<Scalars["String"]["input"]>;
};

export type ExtraPlan = {
  __typename?: "ExtraPlan";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  finalPrice: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ExtraPlanInput = {
  active: Scalars["Boolean"]["input"];
  finalPrice: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  tickets: Scalars["Int"]["input"];
};

export type Faq = {
  __typename?: "FAQ";
  active: Scalars["Boolean"]["output"];
  answer: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  order: Scalars["Int"]["output"];
  question: Scalars["String"]["output"];
};

export type FaqInput = {
  active: Scalars["Boolean"]["input"];
  answer: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  order: Scalars["Int"]["input"];
  question: Scalars["String"]["input"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type Instructor = {
  __typename?: "Instructor";
  createdAt: Scalars["DateTime"]["output"];
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  id: Scalars["ID"]["output"];
  introduction?: Maybe<Scalars["String"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  online: Scalars["Boolean"]["output"];
  qualificationNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type InstructorConnection = {
  __typename?: "InstructorConnection";
  edges: Array<Maybe<Instructor>>;
  pageInfo: PageInfo;
};

export type InstructorInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  qualificationNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Interpreter = {
  __typename?: "Interpreter";
  createdAt: Scalars["DateTime"]["output"];
  educations?: Maybe<Array<Maybe<Education>>>;
  experiences?: Maybe<Array<Maybe<Experience>>>;
  id: Scalars["ID"]["output"];
  introduction?: Maybe<Scalars["String"]["output"]>;
  online: Scalars["Boolean"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  visaNumber?: Maybe<Scalars["String"]["output"]>;
};

export type InterpreterConnection = {
  __typename?: "InterpreterConnection";
  edges: Array<Maybe<Interpreter>>;
  pageInfo: PageInfo;
};

export type InterpreterInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  introduction?: InputMaybe<Scalars["String"]["input"]>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  visaNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Invitation = {
  __typename?: "Invitation";
  createdAt: Scalars["DateTime"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  receiver: Instructor;
  sender: User;
  session?: Maybe<Session>;
  status: InvitationStatus;
  updatedAt: Scalars["DateTime"]["output"];
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
};

export enum InvitationStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Declined = "DECLINED",
  Pending = "PENDING",
}

export type LanguageOption = {
  __typename?: "LanguageOption";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type LanguageOptionInput = {
  active: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type Management = {
  __typename?: "Management";
  address?: Maybe<Scalars["String"]["output"]>;
  ceoName?: Maybe<Scalars["String"]["output"]>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  copyright?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  escroRegistration?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  kakaoAddress?: Maybe<Scalars["String"]["output"]>;
  paymentInformation?: Maybe<Scalars["String"]["output"]>;
  paymentWarning?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  registrationNumber?: Maybe<Scalars["String"]["output"]>;
  sessionDuration: Scalars["Int"]["output"];
  shopAddress?: Maybe<Scalars["String"]["output"]>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ceoName?: InputMaybe<Scalars["String"]["input"]>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  copyright?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  escroRegistration?: InputMaybe<Scalars["String"]["input"]>;
  kakaoAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentInformation?: InputMaybe<Scalars["String"]["input"]>;
  paymentWarning?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  sessionDuration: Scalars["Int"]["input"];
  shopAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  uri: Scalars["String"]["output"];
};

export type MediaInput = {
  file: Scalars["Upload"]["input"];
  thumbnailFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type Message = {
  __typename?: "Message";
  body?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  media?: Maybe<Media>;
  session: Session;
  user: User;
};

export type MessageConnection = {
  __typename?: "MessageConnection";
  edges?: Maybe<Array<Maybe<Message>>>;
  pageInfo: PageInfo;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  addPaymentsAsAdmin: Scalars["Boolean"]["output"];
  addSubscription: UserSubscription;
  cancelMyPendingInvitation: Invitation;
  changeActiveBilling: Billing;
  changeBillingKeyForSubscription: UserSubscription;
  deleteMyAllergy: Scalars["Boolean"]["output"];
  deleteMyChronicDisease: Scalars["Boolean"]["output"];
  deleteMyInstructorEducation: Array<Maybe<Education>>;
  deleteMyInstructorExperience: Array<Maybe<Experience>>;
  deleteMyInsurance: Scalars["Boolean"]["output"];
  deleteMyInterpreterEducation: Array<Maybe<Education>>;
  deleteMyInterpreterExperience: Array<Maybe<Experience>>;
  deleteMyMedicine: Scalars["Boolean"]["output"];
  deleteMySurgery: Scalars["Boolean"]["output"];
  deleteMyVaccine: Scalars["Boolean"]["output"];
  extendSession: SessionExtension;
  joinSession: Session;
  joinSessionAsInterpreter: Session;
  leaveSession: Session;
  purchaseExtraPlanByBilling: Scalars["Boolean"]["output"];
  purchaseUserPassByBilling: Scalars["Boolean"]["output"];
  receiveUserPass?: Maybe<UserPass>;
  requestCancelSubscription: UserSubscription;
  respondToInvitation: Invitation;
  revokeSubscription: UserSubscription;
  sendInvitation: Invitation;
  /**
   * Sends a new message. Publishes updateChat and newMessage.
   * When sending text, attachment is not required.
   * When sending media, text is not required.
   */
  sendMessage: Message;
  sendUserPass: UserPass;
  toggleInterpreterNeeded: Session;
  toggleMyOnlineStatusAsInstructor: Instructor;
  toggleMyOnlineStatusAsInterpreter: Instructor;
  toggleSessionStatusAsInstructor: Session;
  updateAllergySymptomAsAdmin: AllergySymptom;
  updateAllergyTypeAsAdmin: AllergyType;
  updateChronicDiseaseTypeAsAdmin: ChronicDiseaseType;
  updateExtraPlanAsAdmin: ExtraPlan;
  updateFAQAsAdmin: Faq;
  updateLanguageOptionAsAdmin: LanguageOption;
  updateManagementAsAdmin: Management;
  updateMyAllergy: UserAllergy;
  updateMyBankAccount: BankAccount;
  updateMyChronicDisease: UserChronicDisease;
  updateMyInstructor: Instructor;
  updateMyInstructorEducations: Array<Maybe<Education>>;
  updateMyInstructorExperiences: Array<Maybe<Experience>>;
  updateMyInsurance: UserInsurance;
  updateMyInterpreter: Interpreter;
  updateMyInterpreterEducations: Array<Maybe<Education>>;
  updateMyInterpreterExperiences: Array<Maybe<Experience>>;
  updateMyMedicine: UserMedicine;
  updateMyProfile: Profile;
  updateMySurgery: UserSurgery;
  updateMyUserProfile: User;
  updateMyVaccine: UserVaccine;
  updatePassAsAdmin: Pass;
  updatePaymentUnitPriceAsAdmin: PaymentUnitPrice;
  updateSessionReportAsInstructor: Session;
  updateSubscriptionPlanAsAdmin: SubscriptionPlan;
  /** Update user's customerUid which matches billing key for subscription payment. */
  updateUserBilling: Array<Maybe<Billing>>;
  updateVaccineTypeAsAdmin: VaccineType;
};

export type MutationAddPaymentsAsAdminArgs = {
  paymentInput: PaymentInput;
};

export type MutationAddSubscriptionArgs = {
  billingId: Scalars["ID"]["input"];
  finalPrice: Scalars["Float"]["input"];
  subscriptionPlanId: Scalars["ID"]["input"];
};

export type MutationCancelMyPendingInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
};

export type MutationChangeActiveBillingArgs = {
  billingId: Scalars["ID"]["input"];
};

export type MutationChangeBillingKeyForSubscriptionArgs = {
  newBillingId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteMyAllergyArgs = {
  userAllergyId: Scalars["ID"]["input"];
};

export type MutationDeleteMyChronicDiseaseArgs = {
  userChronicDiseaseId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInstructorEducationArgs = {
  educationId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInstructorExperienceArgs = {
  experienceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInsuranceArgs = {
  userInsuranceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInterpreterEducationArgs = {
  educationId: Scalars["ID"]["input"];
};

export type MutationDeleteMyInterpreterExperienceArgs = {
  experienceId: Scalars["ID"]["input"];
};

export type MutationDeleteMyMedicineArgs = {
  userMedicineId: Scalars["ID"]["input"];
};

export type MutationDeleteMySurgeryArgs = {
  userSurgeryId: Scalars["ID"]["input"];
};

export type MutationDeleteMyVaccineArgs = {
  userVaccineId: Scalars["ID"]["input"];
};

export type MutationExtendSessionArgs = {
  sessionId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationJoinSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationJoinSessionAsInterpreterArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationLeaveSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type MutationPurchaseExtraPlanByBillingArgs = {
  billingId: Scalars["ID"]["input"];
  extraPlanId: Scalars["ID"]["input"];
  userExtraPlanId: Scalars["ID"]["input"];
};

export type MutationPurchaseUserPassByBillingArgs = {
  billingId: Scalars["ID"]["input"];
  passId: Scalars["ID"]["input"];
  userPassId: Scalars["ID"]["input"];
};

export type MutationReceiveUserPassArgs = {
  code: Scalars["String"]["input"];
};

export type MutationRequestCancelSubscriptionArgs = {
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationRespondToInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
  status: InvitationStatus;
};

export type MutationRevokeSubscriptionArgs = {
  newBillingId: Scalars["ID"]["input"];
  subscriptionId: Scalars["ID"]["input"];
};

export type MutationSendInvitationArgs = {
  receiverId: Scalars["ID"]["input"];
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
  userSubscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationSendMessageArgs = {
  attachment?: InputMaybe<MediaInput>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  sessionId: Scalars["ID"]["input"];
};

export type MutationSendUserPassArgs = {
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  userPassId: Scalars["ID"]["input"];
};

export type MutationToggleInterpreterNeededArgs = {
  needInterpreter: Scalars["Boolean"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationToggleMyOnlineStatusAsInstructorArgs = {
  status: Scalars["Boolean"]["input"];
};

export type MutationToggleMyOnlineStatusAsInterpreterArgs = {
  status: Scalars["Boolean"]["input"];
};

export type MutationToggleSessionStatusAsInstructorArgs = {
  sessionId: Scalars["ID"]["input"];
  status: SessionStatus;
};

export type MutationUpdateAllergySymptomAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateAllergyTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateChronicDiseaseTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type MutationUpdateExtraPlanAsAdminArgs = {
  extraPlanInput: ExtraPlanInput;
};

export type MutationUpdateFaqAsAdminArgs = {
  faqInput: FaqInput;
};

export type MutationUpdateLanguageOptionAsAdminArgs = {
  languageOptionInput: LanguageOptionInput;
};

export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};

export type MutationUpdateMyAllergyArgs = {
  userAllergyInput: UserAllergyInput;
};

export type MutationUpdateMyBankAccountArgs = {
  bankAccountInput: BankAccountInput;
};

export type MutationUpdateMyChronicDiseaseArgs = {
  userChronicDiseaseInput: UserChronicDiseaseInput;
};

export type MutationUpdateMyInstructorArgs = {
  instructorInput: InstructorInput;
};

export type MutationUpdateMyInstructorEducationsArgs = {
  educationInputs: Array<InputMaybe<EducationInput>>;
};

export type MutationUpdateMyInstructorExperiencesArgs = {
  experienceInputs: Array<InputMaybe<ExperienceInput>>;
};

export type MutationUpdateMyInsuranceArgs = {
  userInsuranceInput: UserInsuranceInput;
};

export type MutationUpdateMyInterpreterArgs = {
  interpreterInput: InterpreterInput;
};

export type MutationUpdateMyInterpreterEducationsArgs = {
  educationInputs: Array<InputMaybe<EducationInput>>;
};

export type MutationUpdateMyInterpreterExperiencesArgs = {
  experienceInputs: Array<InputMaybe<ExperienceInput>>;
};

export type MutationUpdateMyMedicineArgs = {
  userMedicineInput: UserMedicineInput;
};

export type MutationUpdateMyProfileArgs = {
  profileInput: ProfileInput;
};

export type MutationUpdateMySurgeryArgs = {
  userSurgeryInput: UserSurgeryInput;
};

export type MutationUpdateMyUserProfileArgs = {
  userInput: UserInput;
};

export type MutationUpdateMyVaccineArgs = {
  userVaccineInput: UserVaccineInput;
};

export type MutationUpdatePassAsAdminArgs = {
  passInput: PassInput;
};

export type MutationUpdatePaymentUnitPriceAsAdminArgs = {
  paymentUnitPriceInput: PaymentUnitPriceInput;
};

export type MutationUpdateSessionReportAsInstructorArgs = {
  report: Scalars["String"]["input"];
  sessionId: Scalars["ID"]["input"];
};

export type MutationUpdateSubscriptionPlanAsAdminArgs = {
  subscriptionPlanInput: SubscriptionPlanInput;
};

export type MutationUpdateUserBillingArgs = {
  billingInput: BillingInput;
};

export type MutationUpdateVaccineTypeAsAdminArgs = {
  typeInput?: InputMaybe<PhrTypeInput>;
};

export type Nationality = {
  __typename?: "Nationality";
  emoji?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  unicode: Scalars["String"]["output"];
};

export type PhrTypeInput = {
  active: Scalars["Boolean"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type Pass = {
  __typename?: "Pass";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  finalPrice: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PassInput = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  finalPrice: Scalars["Float"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
  tickets: Scalars["Int"]["input"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Float"]["output"];
  bankAccount: BankAccount;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  session?: Maybe<Session>;
  user: User;
};

export type PaymentConnection = {
  __typename?: "PaymentConnection";
  edges: Array<Maybe<Payment>>;
  pageInfo: PageInfo;
};

export type PaymentInput = {
  amount: Scalars["Float"]["input"];
  bankAccountId: Scalars["ID"]["input"];
  currency: Scalars["String"]["input"];
  sessionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  type: PaymentUnitPriceType;
  userId: Scalars["ID"]["input"];
};

export type PaymentUnitPrice = {
  __typename?: "PaymentUnitPrice";
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  type: PaymentUnitPriceType;
  unitPrice: Scalars["Float"]["output"];
};

export type PaymentUnitPriceInput = {
  currency: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  language: Scalars["String"]["input"];
  type: PaymentUnitPriceType;
  unitPrice: Scalars["Float"]["input"];
};

export enum PaymentUnitPriceType {
  Instructor = "INSTRUCTOR",
  Interpreter = "INTERPRETER",
}

export type Profile = {
  __typename?: "Profile";
  bloodType?: Maybe<BloodType>;
  companyName?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  emergencyCallerAddress?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerBloodType?: Maybe<BloodType>;
  emergencyCallerName?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerNationality?: Maybe<Nationality>;
  emergencyCallerPhone?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerPhoneCode?: Maybe<Scalars["String"]["output"]>;
  emergencyCallerRelation?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  occupation?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  weight?: Maybe<Scalars["Float"]["output"]>;
};

export type ProfileInput = {
  bloodType?: InputMaybe<BloodType>;
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerAddress?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerBloodType?: InputMaybe<BloodType>;
  emergencyCallerName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerNationalityId?: InputMaybe<Scalars["ID"]["input"]>;
  emergencyCallerPhone?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerPhoneCode?: InputMaybe<Scalars["String"]["input"]>;
  emergencyCallerRelation?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  occupation?: InputMaybe<Scalars["String"]["input"]>;
  weight?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  getActiveSessions: SessionConnection;
  getAllExtraPlansAsAdmin: Array<Maybe<ExtraPlan>>;
  getAllInstructors: Array<Maybe<Instructor>>;
  getAllInstructorsAsAdmin: InstructorConnection;
  getAllInterpretersAsAdmin: InterpreterConnection;
  getAllPassesAsAdmin: Array<Maybe<Pass>>;
  getAllSubscriptionPlansAsAdmin: Array<Maybe<SubscriptionPlan>>;
  getAllergySymptoms?: Maybe<Array<Maybe<AllergySymptom>>>;
  getAllergySymptomsAsAdmin?: Maybe<Array<Maybe<AllergySymptom>>>;
  getAllergyTypes?: Maybe<Array<Maybe<AllergyType>>>;
  getAllergyTypesAsAdmin?: Maybe<Array<Maybe<AllergyType>>>;
  getChronicDiseaseTypes?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  getChronicDiseaseTypesAsAdmin?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  getExtraPlans: Array<Maybe<ExtraPlan>>;
  getFAQs?: Maybe<Array<Maybe<Faq>>>;
  getInstructor: Instructor;
  getInterpreter: Interpreter;
  getInterpreterUnpaidSessionAsAdmin?: Maybe<Array<Maybe<Session>>>;
  getInvitation?: Maybe<Invitation>;
  getLanguageOptions?: Maybe<Array<Maybe<LanguageOption>>>;
  getManagement: Management;
  getMyActiveSession?: Maybe<Session>;
  getMyAllergies: Array<Maybe<UserAllergy>>;
  getMyBankAccount?: Maybe<BankAccount>;
  getMyBillings?: Maybe<Array<Maybe<Billing>>>;
  getMyChronicDiseases: Array<Maybe<UserChronicDisease>>;
  getMyInstructor: Instructor;
  getMyInstructorEducations: Array<Maybe<Education>>;
  getMyInstructorExperiences: Array<Maybe<Experience>>;
  getMyInsurances: Array<Maybe<UserInsurance>>;
  getMyInterpreter: Interpreter;
  getMyInterpreterEducations: Array<Maybe<Education>>;
  getMyInterpreterExperiences: Array<Maybe<Experience>>;
  getMyMedicines: Array<Maybe<UserMedicine>>;
  getMyOwnUserPasses: UserPassConnection;
  getMyPaymentHistory: PaymentConnection;
  getMyPendingInvitations: Array<Maybe<Invitation>>;
  getMyProfile: Profile;
  getMyPurchasedUserPasses: UserPassConnection;
  getMySessions: SessionConnection;
  getMySubscriptions: Array<Maybe<UserSubscription>>;
  getMySurgerys: Array<Maybe<UserSurgery>>;
  getMyUserProfile: User;
  getMyVaccines: Array<Maybe<UserVaccine>>;
  getNationalities: Array<Maybe<Nationality>>;
  getOfflineInstructors: Array<Maybe<Instructor>>;
  getOnlineInstructors: Array<Maybe<Instructor>>;
  getOnlineInterpreters: Array<Maybe<Interpreter>>;
  getPass: Pass;
  getPassesByLanguage: Array<Maybe<Pass>>;
  getPaymentUnitPricesAsAdmin?: Maybe<Array<Maybe<PaymentUnitPrice>>>;
  getSession: Session;
  /**
   * Get a list of messages in a given chat. Publishes updateChat to notify users
   * that lastSeenAt timestamp is updated.
   *
   * All notifications attached to this chat will be marked as checked.
   *
   * Supports pagination.
   */
  getSessionMessages: MessageConnection;
  getSubscriptionPlan: SubscriptionPlan;
  getSubscriptionPlansByLanguage: Array<Maybe<SubscriptionPlan>>;
  getUnpaidSessionsAsAdmin?: Maybe<Array<Maybe<Session>>>;
  getUser: User;
  getUserAllergies: Array<Maybe<UserAllergy>>;
  getUserBankAccountAsAdmin?: Maybe<BankAccount>;
  getUserChronicDiseases: Array<Maybe<UserChronicDisease>>;
  getUserExtraPlan: UserExtraPlan;
  getUserExtraPlansAsAdmin: UserExtraPlanConnection;
  getUserInsurances: Array<Maybe<UserInsurance>>;
  getUserMedicines: Array<Maybe<UserMedicine>>;
  getUserPass: UserPass;
  getUserPassesAsAdmin: UserPassConnection;
  getUserSubscriptionsAsAdmin: UserSubscriptionConnection;
  getUserSurgerys: Array<Maybe<UserSurgery>>;
  getUserVaccines: Array<Maybe<UserVaccine>>;
  getUsersAsAdmin: UserConnection;
  getVaccineTypes?: Maybe<Array<Maybe<VaccineType>>>;
  getVaccineTypesAsAdmin?: Maybe<Array<Maybe<ChronicDiseaseType>>>;
  searchNationality: Array<Maybe<Nationality>>;
};

export type QueryGetActiveSessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllInstructorsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllInterpretersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetAllergySymptomsArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAllergyTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetChronicDiseaseTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetFaQsArgs = {
  activeOnly: Scalars["Boolean"]["input"];
};

export type QueryGetInstructorArgs = {
  instructorId: Scalars["ID"]["input"];
};

export type QueryGetInterpreterArgs = {
  interpreterId: Scalars["ID"]["input"];
};

export type QueryGetInvitationArgs = {
  invitationId: Scalars["ID"]["input"];
};

export type QueryGetLanguageOptionsArgs = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetMyOwnUserPassesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Array<InputMaybe<UserPassStatus>>>;
};

export type QueryGetMyPaymentHistoryArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  type: PaymentUnitPriceType;
};

export type QueryGetMyPurchasedUserPassesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetMySessionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetPassArgs = {
  passId: Scalars["ID"]["input"];
};

export type QueryGetPassesByLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type QueryGetSessionArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type QueryGetSessionMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sessionId: Scalars["ID"]["input"];
};

export type QueryGetSubscriptionPlanArgs = {
  planId: Scalars["ID"]["input"];
};

export type QueryGetSubscriptionPlansByLanguageArgs = {
  language: Scalars["String"]["input"];
};

export type QueryGetUserArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserAllergiesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserBankAccountAsAdminArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserChronicDiseasesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserExtraPlanArgs = {
  userExtraPlanId: Scalars["ID"]["input"];
};

export type QueryGetUserExtraPlansAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserInsurancesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserMedicinesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserPassArgs = {
  userPassId: Scalars["ID"]["input"];
};

export type QueryGetUserPassesAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserSubscriptionsAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUserSurgerysArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserVaccinesArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUsersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetVaccineTypesArgs = {
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySearchNationalityArgs = {
  query: Scalars["String"]["input"];
};

export type Session = {
  __typename?: "Session";
  createdAt: Scalars["DateTime"]["output"];
  endAt?: Maybe<Scalars["DateTime"]["output"]>;
  extensions?: Maybe<Array<Maybe<SessionExtension>>>;
  id: Scalars["ID"]["output"];
  instructor?: Maybe<Instructor>;
  interpreter?: Maybe<Interpreter>;
  /** If access token returns null, the access to this room cannot be granted */
  jwt?: Maybe<Scalars["String"]["output"]>;
  needInterpreter?: Maybe<Scalars["Boolean"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  report?: Maybe<Scalars["String"]["output"]>;
  requester: User;
  startAt?: Maybe<Scalars["DateTime"]["output"]>;
  status?: Maybe<SessionStatus>;
  updatedAt: Scalars["DateTime"]["output"];
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
  users?: Maybe<Array<Maybe<SessionUser>>>;
};

export type SessionConnection = {
  __typename?: "SessionConnection";
  edges?: Maybe<Array<Maybe<Session>>>;
  pageInfo: PageInfo;
};

export type SessionExtension = {
  __typename?: "SessionExtension";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  session: Session;
  userPass?: Maybe<UserPass>;
  userSubscription?: Maybe<UserSubscription>;
};

export enum SessionStatus {
  Done = "DONE",
  Pending = "PENDING",
  Started = "STARTED",
}

export type SessionUser = {
  __typename?: "SessionUser";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  joinAt: Scalars["DateTime"]["output"];
  leaveAt?: Maybe<Scalars["DateTime"]["output"]>;
  session: Session;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]["output"]>;
  invitationUpdates: Invitation;
  /** Published whenever a new message is sent in a chat. */
  newMessage?: Maybe<Message>;
  sessionUpdate: Session;
  updatedOnlineInstructor: Instructor;
  updatedOnlineInterpreter: Interpreter;
};

export type SubscriptionNewMessageArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type SubscriptionSessionUpdateArgs = {
  sessionId: Scalars["ID"]["input"];
};

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  duration: Scalars["Int"]["output"];
  finalPrice: Scalars["Float"]["output"];
  freeTrial: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  initialPrice: Scalars["Float"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  tickets: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SubscriptionPlanInput = {
  active: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration: Scalars["Int"]["input"];
  finalPrice: Scalars["Float"]["input"];
  freeTrial: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  initialPrice: Scalars["Float"]["input"];
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
  tickets: Scalars["Int"]["input"];
};

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  CancelRequested = "CANCEL_REQUESTED",
  Expired = "EXPIRED",
  Paused = "PAUSED",
}

export type User = {
  __typename?: "User";
  avatar?: Maybe<Media>;
  billings?: Maybe<Array<Maybe<Billing>>>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["Date"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id: Scalars["ID"]["output"];
  instructor?: Maybe<Instructor>;
  interpreter?: Maybe<Interpreter>;
  isSubscriber?: Maybe<Scalars["Boolean"]["output"]>;
  languageOptions?: Maybe<Array<Maybe<LanguageOption>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<Nationality>;
  phone?: Maybe<Scalars["String"]["output"]>;
  phoneCode?: Maybe<Scalars["String"]["output"]>;
  profile?: Maybe<Profile>;
  provider?: Maybe<Scalars["String"]["output"]>;
  resignReason?: Maybe<Scalars["String"]["output"]>;
  type: UserType;
  updatedAt: Scalars["DateTime"]["output"];
  usuableTickets: Scalars["Int"]["output"];
};

export type UserAllergy = {
  __typename?: "UserAllergy";
  createdAt: Scalars["DateTime"]["output"];
  firstAid?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
  symptoms?: Maybe<Array<Maybe<AllergySymptom>>>;
  type: AllergyType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserAllergyInput = {
  firstAid?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  symptomIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  typeId: Scalars["ID"]["input"];
};

export type UserChronicDisease = {
  __typename?: "UserChronicDisease";
  createdAt: Scalars["DateTime"]["output"];
  etc?: Maybe<Scalars["String"]["output"]>;
  firstAid?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  medicine?: Maybe<Scalars["String"]["output"]>;
  type: ChronicDiseaseType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserChronicDiseaseInput = {
  etc?: InputMaybe<Scalars["String"]["input"]>;
  firstAid?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  medicine?: InputMaybe<Scalars["String"]["input"]>;
  typeId: Scalars["ID"]["input"];
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
};

export type UserExtraPlan = {
  __typename?: "UserExtraPlan";
  createdAt: Scalars["DateTime"]["output"];
  extraPlan: ExtraPlan;
  id: Scalars["ID"]["output"];
  purchaser: User;
  status: UserExtraPlanStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserExtraPlanConnection = {
  __typename?: "UserExtraPlanConnection";
  edges?: Maybe<Array<Maybe<UserExtraPlan>>>;
  pageInfo: PageInfo;
};

export enum UserExtraPlanStatus {
  Cancelled = "CANCELLED",
  Success = "SUCCESS",
}

export type UserInput = {
  avatarInput?: InputMaybe<MediaInput>;
  dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  languageOptionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  nationalityId?: InputMaybe<Scalars["ID"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  phoneCode?: InputMaybe<Scalars["String"]["input"]>;
  type: UserType;
};

export type UserInsurance = {
  __typename?: "UserInsurance";
  companyNumber?: Maybe<Scalars["String"]["output"]>;
  coverage?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  groupId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  memberId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserInsuranceInput = {
  companyNumber?: InputMaybe<Scalars["String"]["input"]>;
  coverage?: InputMaybe<Scalars["String"]["input"]>;
  groupId?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  memberId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserMedicine = {
  __typename?: "UserMedicine";
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  dosage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  medicine?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserMedicineInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dosage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  medicine?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserPass = {
  __typename?: "UserPass";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  pass: Pass;
  purchaser: User;
  sent: Scalars["Boolean"]["output"];
  status: UserPassStatus;
  updatedAt: Scalars["DateTime"]["output"];
  user?: Maybe<User>;
};

export type UserPassConnection = {
  __typename?: "UserPassConnection";
  edges?: Maybe<Array<Maybe<UserPass>>>;
  pageInfo: PageInfo;
};

export enum UserPassStatus {
  Cancelled = "CANCELLED",
  Purchased = "PURCHASED",
  Received = "RECEIVED",
  Used = "USED",
}

export type UserSubscription = {
  __typename?: "UserSubscription";
  billing?: Maybe<Billing>;
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  nextPayAt?: Maybe<Scalars["DateTime"]["output"]>;
  status: SubscriptionStatus;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  to?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserSubscriptionConnection = {
  __typename?: "UserSubscriptionConnection";
  edges?: Maybe<Array<Maybe<UserSubscription>>>;
  pageInfo: PageInfo;
};

export type UserSurgery = {
  __typename?: "UserSurgery";
  createdAt: Scalars["DateTime"]["output"];
  department?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  media?: Maybe<Media>;
  part?: Maybe<Scalars["String"]["output"]>;
  surgery?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type UserSurgeryInput = {
  department?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  mediaInput?: InputMaybe<MediaInput>;
  part?: InputMaybe<Scalars["String"]["input"]>;
  surgery?: InputMaybe<Scalars["String"]["input"]>;
};

export enum UserType {
  Instructor = "INSTRUCTOR",
  Interpreter = "INTERPRETER",
  Personal = "PERSONAL",
}

export type UserVaccine = {
  __typename?: "UserVaccine";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  lastVaccinatedAt?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  vaccineType: VaccineType;
};

export type UserVaccineInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastVaccinatedAt?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  vaccineTypeId: Scalars["ID"]["input"];
};

export type VaccineType = {
  __typename?: "VaccineType";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type GetFaQsQueryVariables = Exact<{
  activeOnly: Scalars["Boolean"]["input"];
}>;

export type GetFaQsQuery = {
  __typename?: "Query";
  getFAQs?: Array<{
    __typename?: "FAQ";
    id: string;
    question: string;
    answer: string;
    active: boolean;
    order: number;
  } | null> | null;
};

export type GetInstructorQueryVariables = Exact<{
  instructorId: Scalars["ID"]["input"];
}>;

export type GetInstructorQuery = {
  __typename?: "Query";
  getInstructor: {
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      name?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      job?: string | null;
      place?: string | null;
      duration?: string | null;
    } | null> | null;
  };
};

export type GetOnlineInstructorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOnlineInstructorsQuery = {
  __typename?: "Query";
  getOnlineInstructors: Array<{
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      name?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null>;
};

export type GetOfflineInstructorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetOfflineInstructorsQuery = {
  __typename?: "Query";
  getOfflineInstructors: Array<{
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      name?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null>;
};

export type GetAllInstructorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllInstructorsQuery = {
  __typename?: "Query";
  getAllInstructors: Array<{
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      name?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  } | null>;
};

export type UpdatedOnlineInstructorSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type UpdatedOnlineInstructorSubscription = {
  __typename?: "Subscription";
  updatedOnlineInstructor: {
    __typename?: "Instructor";
    id: string;
    introduction?: string | null;
    job?: string | null;
    qualificationNumber?: string | null;
    online: boolean;
    createdAt: any;
    updatedAt: any;
    user: {
      __typename?: "User";
      id: string;
      name?: string | null;
      avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    };
    educations?: Array<{
      __typename?: "Education";
      id: string;
      school?: string | null;
      major?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    experiences?: Array<{
      __typename?: "Experience";
      id: string;
      place?: string | null;
      job?: string | null;
      duration?: string | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
  };
};

export type GetInvitationQueryVariables = Exact<{
  invitationId: Scalars["ID"]["input"];
}>;

export type GetInvitationQuery = {
  __typename?: "Query";
  getInvitation?: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: {
      __typename?: "User";
      id: string;
      usuableTickets: number;
      isSubscriber?: boolean | null;
    };
    receiver: {
      __typename?: "Instructor";
      id: string;
      online: boolean;
      user: { __typename?: "User"; id: string; name?: string | null };
    };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  } | null;
};

export type SendInvitationMutationVariables = Exact<{
  receiverId: Scalars["ID"]["input"];
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
  userSubscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type SendInvitationMutation = {
  __typename?: "Mutation";
  sendInvitation: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: {
      __typename?: "User";
      id: string;
      usuableTickets: number;
      isSubscriber?: boolean | null;
    };
    receiver: {
      __typename?: "Instructor";
      id: string;
      online: boolean;
      user: { __typename?: "User"; id: string; name?: string | null };
    };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  };
};

export type CancelMyPendingInvitationMutationVariables = Exact<{
  invitationId: Scalars["ID"]["input"];
}>;

export type CancelMyPendingInvitationMutation = {
  __typename?: "Mutation";
  cancelMyPendingInvitation: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: {
      __typename?: "User";
      id: string;
      usuableTickets: number;
      isSubscriber?: boolean | null;
    };
    receiver: {
      __typename?: "Instructor";
      id: string;
      online: boolean;
      user: { __typename?: "User"; id: string; name?: string | null };
    };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  };
};

export type InvitationUpdatesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvitationUpdatesSubscription = {
  __typename?: "Subscription";
  invitationUpdates: {
    __typename?: "Invitation";
    id: string;
    status: InvitationStatus;
    createdAt: any;
    updatedAt: any;
    expiresAt: any;
    sender: { __typename?: "User"; id: string; name?: string | null };
    receiver: { __typename?: "Instructor"; id: string };
    session?: {
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
    } | null;
  };
};

export type GetPassesByLanguageQueryVariables = Exact<{
  language: Scalars["String"]["input"];
}>;

export type GetPassesByLanguageQuery = {
  __typename?: "Query";
  getPassesByLanguage: Array<{
    __typename?: "Pass";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null>;
  getSubscriptionPlansByLanguage: Array<{
    __typename?: "SubscriptionPlan";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    duration: number;
    freeTrial: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null>;
};

export type GetPassQueryVariables = Exact<{
  passId: Scalars["ID"]["input"];
}>;

export type GetPassQuery = {
  __typename?: "Query";
  getPass: {
    __typename?: "Pass";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetSubscriptionPlanQueryVariables = Exact<{
  planId: Scalars["ID"]["input"];
}>;

export type GetSubscriptionPlanQuery = {
  __typename?: "Query";
  getSubscriptionPlan: {
    __typename?: "SubscriptionPlan";
    id: string;
    language: string;
    name: string;
    summary?: string | null;
    description?: string | null;
    initialPrice: number;
    finalPrice: number;
    tickets: number;
    duration: number;
    freeTrial: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetLanguageOptionsQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetLanguageOptionsQuery = {
  __typename?: "Query";
  getLanguageOptions?: Array<{
    __typename?: "LanguageOption";
    id: string;
    language: string;
    name: string;
    active: boolean;
    order: number;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetManagementQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagementQuery = {
  __typename?: "Query";
  getManagement: {
    __typename?: "Management";
    id: string;
    companyName?: string | null;
    address?: string | null;
    copyright?: string | null;
    email?: string | null;
    ceoName?: string | null;
    registrationNumber?: string | null;
    escroRegistration?: string | null;
    phone?: string | null;
    kakaoAddress?: string | null;
    sessionDuration: number;
    shopAddress?: string | null;
    paymentWarning?: string | null;
    paymentInformation?: string | null;
  };
};

export type GetNationalitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetNationalitiesQuery = {
  __typename?: "Query";
  getNationalities: Array<{
    __typename?: "Nationality";
    id: string;
    name: string;
    emoji?: string | null;
    unicode: string;
    imageUrl?: string | null;
  } | null>;
};

export type GetMyOwnUserPassesQueryVariables = Exact<{
  status?: InputMaybe<
    Array<InputMaybe<UserPassStatus>> | InputMaybe<UserPassStatus>
  >;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMyOwnUserPassesQuery = {
  __typename?: "Query";
  getMyOwnUserPasses: {
    __typename?: "UserPassConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "UserPass";
      id: string;
      sent: boolean;
      code: string;
      status: UserPassStatus;
      createdAt: any;
      updatedAt: any;
      purchaser: { __typename?: "User"; id: string };
      user?: { __typename?: "User"; id: string } | null;
      pass: {
        __typename?: "Pass";
        id: string;
        language: string;
        name: string;
        summary?: string | null;
        description?: string | null;
        initialPrice: number;
        finalPrice: number;
        tickets: number;
        active: boolean;
        createdAt: any;
        updatedAt: any;
      };
    } | null> | null;
  };
};

export type ReceiveUserPassMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type ReceiveUserPassMutation = {
  __typename?: "Mutation";
  receiveUserPass?: {
    __typename?: "UserPass";
    id: string;
    sent: boolean;
    code: string;
    status: UserPassStatus;
    createdAt: any;
    updatedAt: any;
    purchaser: { __typename?: "User"; id: string };
    user?: { __typename?: "User"; id: string } | null;
    pass: {
      __typename?: "Pass";
      id: string;
      language: string;
      name: string;
      summary?: string | null;
      description?: string | null;
      initialPrice: number;
      finalPrice: number;
      tickets: number;
      active: boolean;
      createdAt: any;
      updatedAt: any;
    };
  } | null;
};

export type SendUserPassMutationVariables = Exact<{
  userPassId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
}>;

export type SendUserPassMutation = {
  __typename?: "Mutation";
  sendUserPass: {
    __typename?: "UserPass";
    id: string;
    code: string;
    status: UserPassStatus;
    sent: boolean;
    createdAt: any;
    updatedAt: any;
    purchaser: { __typename?: "User"; id: string };
    user?: { __typename?: "User"; id: string } | null;
    pass: {
      __typename?: "Pass";
      id: string;
      language: string;
      name: string;
      summary?: string | null;
      description?: string | null;
      initialPrice: number;
      finalPrice: number;
      tickets: number;
      active: boolean;
      createdAt: any;
      updatedAt: any;
    };
  };
};

export type GetMyChronicDiseasesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMyChronicDiseasesQuery = {
  __typename?: "Query";
  getMyChronicDiseases: Array<{
    __typename?: "UserChronicDisease";
    id: string;
    etc?: string | null;
    medicine?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: { __typename?: "ChronicDiseaseType"; id: string; name: string };
  } | null>;
};

export type GetMyAllergiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyAllergiesQuery = {
  __typename?: "Query";
  getMyAllergies: Array<{
    __typename?: "UserAllergy";
    id: string;
    summary?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: { __typename?: "AllergyType"; id: string; name: string };
    symptoms?: Array<{
      __typename?: "AllergySymptom";
      id: string;
      name: string;
    } | null> | null;
  } | null>;
};

export type GetMyMedicinesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyMedicinesQuery = {
  __typename?: "Query";
  getMyMedicines: Array<{
    __typename?: "UserMedicine";
    id: string;
    medicine?: string | null;
    dosage?: string | null;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null>;
};

export type GetMyVaccinesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyVaccinesQuery = {
  __typename?: "Query";
  getMyVaccines: Array<{
    __typename?: "UserVaccine";
    id: string;
    level?: string | null;
    lastVaccinatedAt?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    vaccineType: { __typename?: "VaccineType"; id: string; name: string };
  } | null>;
};

export type GetMySurgerysQueryVariables = Exact<{ [key: string]: never }>;

export type GetMySurgerysQuery = {
  __typename?: "Query";
  getMySurgerys: Array<{
    __typename?: "UserSurgery";
    id: string;
    surgery?: string | null;
    part?: string | null;
    department?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    media?: {
      __typename?: "Media";
      id: string;
      uri: string;
      name: string;
    } | null;
  } | null>;
};

export type GetMyInsurancesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyInsurancesQuery = {
  __typename?: "Query";
  getMyInsurances: Array<{
    __typename?: "UserInsurance";
    id: string;
    type?: string | null;
    name?: string | null;
    coverage?: string | null;
    memberId?: string | null;
    groupId?: string | null;
    companyNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null>;
};

export type UpdateMyChronicDiseaseMutationVariables = Exact<{
  userChronicDiseaseInput: UserChronicDiseaseInput;
}>;

export type UpdateMyChronicDiseaseMutation = {
  __typename?: "Mutation";
  updateMyChronicDisease: {
    __typename?: "UserChronicDisease";
    id: string;
    etc?: string | null;
    medicine?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: { __typename?: "ChronicDiseaseType"; id: string; name: string };
  };
};

export type UpdateMyAllergyMutationVariables = Exact<{
  userAllergyInput: UserAllergyInput;
}>;

export type UpdateMyAllergyMutation = {
  __typename?: "Mutation";
  updateMyAllergy: {
    __typename?: "UserAllergy";
    id: string;
    summary?: string | null;
    firstAid?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    type: { __typename?: "AllergyType"; id: string; name: string };
    symptoms?: Array<{
      __typename?: "AllergySymptom";
      id: string;
      name: string;
    } | null> | null;
  };
};

export type UpdateMyMedicineMutationVariables = Exact<{
  userMedicineInput: UserMedicineInput;
}>;

export type UpdateMyMedicineMutation = {
  __typename?: "Mutation";
  updateMyMedicine: {
    __typename?: "UserMedicine";
    id: string;
    medicine?: string | null;
    dosage?: string | null;
    description?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  };
};

export type UpdateMyVaccineMutationVariables = Exact<{
  userVaccineInput: UserVaccineInput;
}>;

export type UpdateMyVaccineMutation = {
  __typename?: "Mutation";
  updateMyVaccine: {
    __typename?: "UserVaccine";
    id: string;
    level?: string | null;
    lastVaccinatedAt?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    vaccineType: { __typename?: "VaccineType"; id: string; name: string };
  };
};

export type UpdateMySurgeryMutationVariables = Exact<{
  userSurgeryInput: UserSurgeryInput;
}>;

export type UpdateMySurgeryMutation = {
  __typename?: "Mutation";
  updateMySurgery: {
    __typename?: "UserSurgery";
    id: string;
    surgery?: string | null;
    part?: string | null;
    department?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    media?: {
      __typename?: "Media";
      id: string;
      uri: string;
      name: string;
    } | null;
  };
};

export type UpdateMyInsuranceMutationVariables = Exact<{
  userInsuranceInput: UserInsuranceInput;
}>;

export type UpdateMyInsuranceMutation = {
  __typename?: "Mutation";
  updateMyInsurance: {
    __typename?: "UserInsurance";
    id: string;
    type?: string | null;
    name?: string | null;
    coverage?: string | null;
    memberId?: string | null;
    groupId?: string | null;
    companyNumber?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  };
};

export type DeleteMyChronicDiseaseMutationVariables = Exact<{
  userChronicDiseaseId: Scalars["ID"]["input"];
}>;

export type DeleteMyChronicDiseaseMutation = {
  __typename?: "Mutation";
  deleteMyChronicDisease: boolean;
};

export type DeleteMyAllergyMutationVariables = Exact<{
  userAllergyId: Scalars["ID"]["input"];
}>;

export type DeleteMyAllergyMutation = {
  __typename?: "Mutation";
  deleteMyAllergy: boolean;
};

export type DeleteMyMedicineMutationVariables = Exact<{
  userMedicineId: Scalars["ID"]["input"];
}>;

export type DeleteMyMedicineMutation = {
  __typename?: "Mutation";
  deleteMyMedicine: boolean;
};

export type DeleteMyVaccineMutationVariables = Exact<{
  userVaccineId: Scalars["ID"]["input"];
}>;

export type DeleteMyVaccineMutation = {
  __typename?: "Mutation";
  deleteMyVaccine: boolean;
};

export type DeleteMySurgeryMutationVariables = Exact<{
  userSurgeryId: Scalars["ID"]["input"];
}>;

export type DeleteMySurgeryMutation = {
  __typename?: "Mutation";
  deleteMySurgery: boolean;
};

export type DeleteMyInsuranceMutationVariables = Exact<{
  userInsuranceId: Scalars["ID"]["input"];
}>;

export type DeleteMyInsuranceMutation = {
  __typename?: "Mutation";
  deleteMyInsurance: boolean;
};

export type GetChronicDiseaseTypesQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>;
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetChronicDiseaseTypesQuery = {
  __typename?: "Query";
  getChronicDiseaseTypes?: Array<{
    __typename?: "ChronicDiseaseType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetAllergyTypesQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>;
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetAllergyTypesQuery = {
  __typename?: "Query";
  getAllergyTypes?: Array<{
    __typename?: "AllergyType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetAllergySymptomsQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>;
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetAllergySymptomsQuery = {
  __typename?: "Query";
  getAllergySymptoms?: Array<{
    __typename?: "AllergySymptom";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetVaccineTypesQueryVariables = Exact<{
  language?: InputMaybe<Scalars["String"]["input"]>;
  activeOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetVaccineTypesQuery = {
  __typename?: "Query";
  getVaccineTypes?: Array<{
    __typename?: "VaccineType";
    id: string;
    language?: string | null;
    name: string;
    order: number;
    active: boolean;
    createdAt: any;
    updatedAt: any;
  } | null> | null;
};

export type GetMySessionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMySessionsQuery = {
  __typename?: "Query";
  getMySessions: {
    __typename?: "SessionConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "Session";
      id: string;
      jwt?: string | null;
      password?: string | null;
      startAt?: any | null;
      endAt?: any | null;
      report?: string | null;
      status?: SessionStatus | null;
      needInterpreter?: boolean | null;
      createdAt: any;
      updatedAt: any;
      requester: {
        __typename?: "User";
        id: string;
        name?: string | null;
        usuableTickets: number;
      };
      instructor?: {
        __typename?: "Instructor";
        id: string;
        user: {
          __typename?: "User";
          id: string;
          name?: string | null;
          avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        };
      } | null;
      users?: Array<{
        __typename?: "SessionUser";
        id: string;
        joinAt: any;
        leaveAt?: any | null;
        active: boolean;
        user: {
          __typename?: "User";
          id: string;
          name?: string | null;
          avatar?: { __typename?: "Media"; id: string; uri: string } | null;
          instructor?: {
            __typename?: "Instructor";
            id: string;
            job?: string | null;
            experiences?: Array<{
              __typename?: "Experience";
              id: string;
              place?: string | null;
              job?: string | null;
            } | null> | null;
          } | null;
          interpreter?: {
            __typename?: "Interpreter";
            id: string;
            experiences?: Array<{
              __typename?: "Experience";
              id: string;
              place?: string | null;
              job?: string | null;
            } | null> | null;
          } | null;
        };
      } | null> | null;
    } | null> | null;
  };
};

export type GetSessionQueryVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type GetSessionQuery = {
  __typename?: "Query";
  getSession: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    password?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    needInterpreter?: boolean | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
};

export type GetMyActiveSessionQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyActiveSessionQuery = {
  __typename?: "Query";
  getMyActiveSession?: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  } | null;
};

export type JoinSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type JoinSessionMutation = {
  __typename?: "Mutation";
  joinSession: {
    __typename?: "Session";
    id: string;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
  };
};

export type LeaveSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type LeaveSessionMutation = {
  __typename?: "Mutation";
  leaveSession: {
    __typename?: "Session";
    id: string;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type ToggleSessionStatusAsInstructorMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  status: SessionStatus;
}>;

export type ToggleSessionStatusAsInstructorMutation = {
  __typename?: "Mutation";
  toggleSessionStatusAsInstructor: {
    __typename?: "Session";
    id: string;
    endAt?: any | null;
    status?: SessionStatus | null;
  };
};

export type ToggleInterpreterNeededMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  needInterpreter: Scalars["Boolean"]["input"];
}>;

export type ToggleInterpreterNeededMutation = {
  __typename?: "Mutation";
  toggleInterpreterNeeded: {
    __typename?: "Session";
    id: string;
    needInterpreter?: boolean | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type SessionUpdateSubscriptionVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type SessionUpdateSubscription = {
  __typename?: "Subscription";
  sessionUpdate: {
    __typename?: "Session";
    id: string;
    needInterpreter?: boolean | null;
    jwt?: string | null;
    startAt?: any | null;
    endAt?: any | null;
    report?: string | null;
    status?: SessionStatus | null;
    createdAt: any;
    updatedAt: any;
    requester: {
      __typename?: "User";
      id: string;
      name?: string | null;
      usuableTickets: number;
    };
    instructor?: {
      __typename?: "Instructor";
      id: string;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
      };
    } | null;
    users?: Array<{
      __typename?: "SessionUser";
      id: string;
      joinAt: any;
      leaveAt?: any | null;
      active: boolean;
      user: {
        __typename?: "User";
        id: string;
        name?: string | null;
        avatar?: { __typename?: "Media"; id: string; uri: string } | null;
        instructor?: {
          __typename?: "Instructor";
          id: string;
          job?: string | null;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
        interpreter?: {
          __typename?: "Interpreter";
          id: string;
          experiences?: Array<{
            __typename?: "Experience";
            id: string;
            place?: string | null;
            job?: string | null;
          } | null> | null;
        } | null;
      };
    } | null> | null;
    extensions?: Array<{
      __typename?: "SessionExtension";
      id: string;
      createdAt: any;
      session: { __typename?: "Session"; id: string };
      userPass?: { __typename?: "UserPass"; id: string } | null;
      userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
    } | null> | null;
  };
};

export type GetSessionMessagesQueryVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetSessionMessagesQuery = {
  __typename?: "Query";
  getSessionMessages: {
    __typename?: "MessageConnection";
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      endCursor?: string | null;
    };
    edges?: Array<{
      __typename?: "Message";
      id: string;
      body?: string | null;
      createdAt: any;
      session: {
        __typename?: "Session";
        id: string;
        status?: SessionStatus | null;
      };
      user: { __typename?: "User"; id: string; name?: string | null };
      media?: { __typename?: "Media"; id: string; uri: string } | null;
    } | null> | null;
  };
};

export type NewMessageSubscriptionVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
}>;

export type NewMessageSubscription = {
  __typename?: "Subscription";
  newMessage?: {
    __typename?: "Message";
    id: string;
    body?: string | null;
    createdAt: any;
    session: {
      __typename?: "Session";
      id: string;
      status?: SessionStatus | null;
    };
    user: { __typename?: "User"; id: string; name?: string | null };
    media?: { __typename?: "Media"; id: string; uri: string } | null;
  } | null;
};

export type SendMessageMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  body?: InputMaybe<Scalars["String"]["input"]>;
  attachment?: InputMaybe<MediaInput>;
}>;

export type SendMessageMutation = {
  __typename?: "Mutation";
  sendMessage: {
    __typename?: "Message";
    id: string;
    body?: string | null;
    createdAt: any;
    session: {
      __typename?: "Session";
      id: string;
      status?: SessionStatus | null;
    };
    user: { __typename?: "User"; id: string; name?: string | null };
    media?: { __typename?: "Media"; id: string; uri: string } | null;
  };
};

export type ExtendSessionMutationVariables = Exact<{
  sessionId: Scalars["ID"]["input"];
  userPassId?: InputMaybe<Scalars["ID"]["input"]>;
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ExtendSessionMutation = {
  __typename?: "Mutation";
  extendSession: {
    __typename?: "SessionExtension";
    id: string;
    createdAt: any;
    session: {
      __typename?: "Session";
      id: string;
      status?: SessionStatus | null;
    };
    userPass?: { __typename?: "UserPass"; id: string } | null;
    userSubscription?: { __typename?: "UserSubscription"; id: string } | null;
  };
};

export type AddSubscriptionMutationVariables = Exact<{
  subscriptionPlanId: Scalars["ID"]["input"];
  billingId: Scalars["ID"]["input"];
  finalPrice: Scalars["Float"]["input"];
}>;

export type AddSubscriptionMutation = {
  __typename?: "Mutation";
  addSubscription: {
    __typename?: "UserSubscription";
    id: string;
    from: any;
    to?: any | null;
    nextPayAt?: any | null;
    status: SubscriptionStatus;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    subscriptionPlan?: { __typename?: "SubscriptionPlan"; id: string } | null;
    billing?: { __typename?: "Billing"; id: string } | null;
  };
};

export type RequestCancelSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars["ID"]["input"];
}>;

export type RequestCancelSubscriptionMutation = {
  __typename?: "Mutation";
  requestCancelSubscription: {
    __typename?: "UserSubscription";
    id: string;
    from: any;
    to?: any | null;
    nextPayAt?: any | null;
    status: SubscriptionStatus;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    subscriptionPlan?: { __typename?: "SubscriptionPlan"; id: string } | null;
    billing?: { __typename?: "Billing"; id: string } | null;
  };
};

export type RevokeSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars["ID"]["input"];
  newBillingId: Scalars["ID"]["input"];
}>;

export type RevokeSubscriptionMutation = {
  __typename?: "Mutation";
  revokeSubscription: {
    __typename?: "UserSubscription";
    id: string;
    from: any;
    to?: any | null;
    nextPayAt?: any | null;
    status: SubscriptionStatus;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    subscriptionPlan?: { __typename?: "SubscriptionPlan"; id: string } | null;
    billing?: { __typename?: "Billing"; id: string } | null;
  };
};

export type GetMySubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMySubscriptionsQuery = {
  __typename?: "Query";
  getMySubscriptions: Array<{
    __typename?: "UserSubscription";
    id: string;
    from: any;
    to?: any | null;
    nextPayAt?: any | null;
    status: SubscriptionStatus;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    subscriptionPlan?: {
      __typename?: "SubscriptionPlan";
      id: string;
      name: string;
      tickets: number;
      finalPrice: number;
    } | null;
    billing?: { __typename?: "Billing"; id: string } | null;
  } | null>;
};

export type ChangeBillingKeyForSubscriptionMutationVariables = Exact<{
  newBillingId: Scalars["ID"]["input"];
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ChangeBillingKeyForSubscriptionMutation = {
  __typename?: "Mutation";
  changeBillingKeyForSubscription: {
    __typename?: "UserSubscription";
    id: string;
    from: any;
    to?: any | null;
    nextPayAt?: any | null;
    status: SubscriptionStatus;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    subscriptionPlan?: { __typename?: "SubscriptionPlan"; id: string } | null;
    billing?: { __typename?: "Billing"; id: string } | null;
  };
};

export type GetMyUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserProfileQuery = {
  __typename?: "Query";
  getMyUserProfile: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    isSubscriber?: boolean | null;
    usuableTickets: number;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: { __typename?: "Profile"; id: string } | null;
    instructor?: { __typename?: "Instructor"; id: string } | null;
    interpreter?: { __typename?: "Interpreter"; id: string } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      name: string;
    } | null> | null;
  };
};

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyProfileQuery = {
  __typename?: "Query";
  getMyProfile: {
    __typename?: "Profile";
    id: string;
    weight?: number | null;
    height?: number | null;
    bloodType?: BloodType | null;
    occupation?: string | null;
    companyName?: string | null;
    emergencyCallerName?: string | null;
    emergencyCallerPhoneCode?: string | null;
    emergencyCallerPhone?: string | null;
    emergencyCallerBloodType?: BloodType | null;
    emergencyCallerRelation?: string | null;
    emergencyCallerAddress?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    emergencyCallerNationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
  };
};

export type GetMyBillingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyBillingsQuery = {
  __typename?: "Query";
  getMyBillings?: Array<{
    __typename?: "Billing";
    id: string;
    cardName?: string | null;
    cardNumber?: string | null;
    pgProvider?: string | null;
    customerUid: string;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null> | null;
};

export type ChangeActiveBillingMutationVariables = Exact<{
  billingId: Scalars["ID"]["input"];
}>;

export type ChangeActiveBillingMutation = {
  __typename?: "Mutation";
  changeActiveBilling: {
    __typename?: "Billing";
    id: string;
    cardName?: string | null;
    cardNumber?: string | null;
    pgProvider?: string | null;
    customerUid: string;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  };
};

export type GetUserPassQueryVariables = Exact<{
  userPassId: Scalars["ID"]["input"];
}>;

export type GetUserPassQuery = {
  __typename?: "Query";
  getUserPass: {
    __typename?: "UserPass";
    id: string;
    code: string;
    status: UserPassStatus;
    createdAt: any;
    updatedAt: any;
    purchaser: { __typename?: "User"; id: string };
    user?: { __typename?: "User"; id: string } | null;
    pass: { __typename?: "Pass"; id: string };
  };
};

export type UpdateUserBillingMutationVariables = Exact<{
  billingInput: BillingInput;
}>;

export type UpdateUserBillingMutation = {
  __typename?: "Mutation";
  updateUserBilling: Array<{
    __typename?: "Billing";
    id: string;
    cardName?: string | null;
    cardNumber?: string | null;
    pgProvider?: string | null;
    customerUid: string;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  } | null>;
};

export type UpdateMyUserProfileMutationVariables = Exact<{
  userInput: UserInput;
}>;

export type UpdateMyUserProfileMutation = {
  __typename?: "Mutation";
  updateMyUserProfile: {
    __typename?: "User";
    id: string;
    type: UserType;
    email?: string | null;
    name?: string | null;
    gender?: Gender | null;
    phoneCode?: string | null;
    phone?: string | null;
    dateOfBirth?: any | null;
    provider?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    avatar?: { __typename?: "Media"; id: string; uri: string } | null;
    nationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
    profile?: { __typename?: "Profile"; id: string } | null;
    instructor?: { __typename?: "Instructor"; id: string } | null;
    interpreter?: { __typename?: "Interpreter"; id: string } | null;
    languageOptions?: Array<{
      __typename?: "LanguageOption";
      id: string;
      name: string;
    } | null> | null;
  };
};

export type UpdateMyProfileMutationVariables = Exact<{
  profileInput: ProfileInput;
}>;

export type UpdateMyProfileMutation = {
  __typename?: "Mutation";
  updateMyProfile: {
    __typename?: "Profile";
    id: string;
    weight?: number | null;
    height?: number | null;
    bloodType?: BloodType | null;
    occupation?: string | null;
    companyName?: string | null;
    emergencyCallerName?: string | null;
    emergencyCallerPhoneCode?: string | null;
    emergencyCallerPhone?: string | null;
    emergencyCallerBloodType?: BloodType | null;
    emergencyCallerRelation?: string | null;
    emergencyCallerAddress?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
    emergencyCallerNationality?: {
      __typename?: "Nationality";
      id: string;
      name: string;
    } | null;
  };
};

export type PurchaseUserPassByBillingMutationVariables = Exact<{
  passId: Scalars["ID"]["input"];
  billingId: Scalars["ID"]["input"];
  userPassId: Scalars["ID"]["input"];
}>;

export type PurchaseUserPassByBillingMutation = {
  __typename?: "Mutation";
  purchaseUserPassByBilling: boolean;
};

export const GetFaQsDocument = gql`
  query GetFAQs($activeOnly: Boolean!) {
    getFAQs(activeOnly: $activeOnly) {
      id
      question
      answer
      active
      order
    }
  }
`;

/**
 * __useGetFaQsQuery__
 *
 * To run a query within a React component, call `useGetFaQsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaQsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaQsQuery({
 *   variables: {
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetFaQsQuery(
  baseOptions: Apollo.QueryHookOptions<GetFaQsQuery, GetFaQsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFaQsQuery, GetFaQsQueryVariables>(
    GetFaQsDocument,
    options
  );
}
export function useGetFaQsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFaQsQuery, GetFaQsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFaQsQuery, GetFaQsQueryVariables>(
    GetFaQsDocument,
    options
  );
}
export function useGetFaQsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFaQsQuery,
    GetFaQsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFaQsQuery, GetFaQsQueryVariables>(
    GetFaQsDocument,
    options
  );
}
export type GetFaQsQueryHookResult = ReturnType<typeof useGetFaQsQuery>;
export type GetFaQsLazyQueryHookResult = ReturnType<typeof useGetFaQsLazyQuery>;
export type GetFaQsSuspenseQueryHookResult = ReturnType<
  typeof useGetFaQsSuspenseQuery
>;
export type GetFaQsQueryResult = Apollo.QueryResult<
  GetFaQsQuery,
  GetFaQsQueryVariables
>;
export const GetInstructorDocument = gql`
  query GetInstructor($instructorId: ID!) {
    getInstructor(instructorId: $instructorId) {
      id
      user {
        id
        name
        avatar {
          id
          uri
        }
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
      }
      experiences {
        id
        job
        place
        duration
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetInstructorQuery__
 *
 * To run a query within a React component, call `useGetInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorQuery({
 *   variables: {
 *      instructorId: // value for 'instructorId'
 *   },
 * });
 */
export function useGetInstructorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstructorQuery,
    GetInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInstructorQuery, GetInstructorQueryVariables>(
    GetInstructorDocument,
    options
  );
}
export function useGetInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstructorQuery,
    GetInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInstructorQuery, GetInstructorQueryVariables>(
    GetInstructorDocument,
    options
  );
}
export function useGetInstructorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInstructorQuery,
    GetInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInstructorQuery,
    GetInstructorQueryVariables
  >(GetInstructorDocument, options);
}
export type GetInstructorQueryHookResult = ReturnType<
  typeof useGetInstructorQuery
>;
export type GetInstructorLazyQueryHookResult = ReturnType<
  typeof useGetInstructorLazyQuery
>;
export type GetInstructorSuspenseQueryHookResult = ReturnType<
  typeof useGetInstructorSuspenseQuery
>;
export type GetInstructorQueryResult = Apollo.QueryResult<
  GetInstructorQuery,
  GetInstructorQueryVariables
>;
export const GetOnlineInstructorsDocument = gql`
  query GetOnlineInstructors {
    getOnlineInstructors {
      id
      user {
        id
        name
        avatar {
          id
          uri
        }
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetOnlineInstructorsQuery__
 *
 * To run a query within a React component, call `useGetOnlineInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnlineInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnlineInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnlineInstructorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnlineInstructorsQuery,
    GetOnlineInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnlineInstructorsQuery,
    GetOnlineInstructorsQueryVariables
  >(GetOnlineInstructorsDocument, options);
}
export function useGetOnlineInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnlineInstructorsQuery,
    GetOnlineInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnlineInstructorsQuery,
    GetOnlineInstructorsQueryVariables
  >(GetOnlineInstructorsDocument, options);
}
export function useGetOnlineInstructorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOnlineInstructorsQuery,
    GetOnlineInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOnlineInstructorsQuery,
    GetOnlineInstructorsQueryVariables
  >(GetOnlineInstructorsDocument, options);
}
export type GetOnlineInstructorsQueryHookResult = ReturnType<
  typeof useGetOnlineInstructorsQuery
>;
export type GetOnlineInstructorsLazyQueryHookResult = ReturnType<
  typeof useGetOnlineInstructorsLazyQuery
>;
export type GetOnlineInstructorsSuspenseQueryHookResult = ReturnType<
  typeof useGetOnlineInstructorsSuspenseQuery
>;
export type GetOnlineInstructorsQueryResult = Apollo.QueryResult<
  GetOnlineInstructorsQuery,
  GetOnlineInstructorsQueryVariables
>;
export const GetOfflineInstructorsDocument = gql`
  query GetOfflineInstructors {
    getOfflineInstructors {
      id
      user {
        id
        name
        avatar {
          id
          uri
        }
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetOfflineInstructorsQuery__
 *
 * To run a query within a React component, call `useGetOfflineInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfflineInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfflineInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfflineInstructorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOfflineInstructorsQuery,
    GetOfflineInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOfflineInstructorsQuery,
    GetOfflineInstructorsQueryVariables
  >(GetOfflineInstructorsDocument, options);
}
export function useGetOfflineInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfflineInstructorsQuery,
    GetOfflineInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOfflineInstructorsQuery,
    GetOfflineInstructorsQueryVariables
  >(GetOfflineInstructorsDocument, options);
}
export function useGetOfflineInstructorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOfflineInstructorsQuery,
    GetOfflineInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOfflineInstructorsQuery,
    GetOfflineInstructorsQueryVariables
  >(GetOfflineInstructorsDocument, options);
}
export type GetOfflineInstructorsQueryHookResult = ReturnType<
  typeof useGetOfflineInstructorsQuery
>;
export type GetOfflineInstructorsLazyQueryHookResult = ReturnType<
  typeof useGetOfflineInstructorsLazyQuery
>;
export type GetOfflineInstructorsSuspenseQueryHookResult = ReturnType<
  typeof useGetOfflineInstructorsSuspenseQuery
>;
export type GetOfflineInstructorsQueryResult = Apollo.QueryResult<
  GetOfflineInstructorsQuery,
  GetOfflineInstructorsQueryVariables
>;
export const GetAllInstructorsDocument = gql`
  query GetAllInstructors {
    getAllInstructors {
      id
      user {
        id
        name
        avatar {
          id
          uri
        }
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllInstructorsQuery__
 *
 * To run a query within a React component, call `useGetAllInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllInstructorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllInstructorsQuery,
    GetAllInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllInstructorsQuery,
    GetAllInstructorsQueryVariables
  >(GetAllInstructorsDocument, options);
}
export function useGetAllInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllInstructorsQuery,
    GetAllInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllInstructorsQuery,
    GetAllInstructorsQueryVariables
  >(GetAllInstructorsDocument, options);
}
export function useGetAllInstructorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllInstructorsQuery,
    GetAllInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllInstructorsQuery,
    GetAllInstructorsQueryVariables
  >(GetAllInstructorsDocument, options);
}
export type GetAllInstructorsQueryHookResult = ReturnType<
  typeof useGetAllInstructorsQuery
>;
export type GetAllInstructorsLazyQueryHookResult = ReturnType<
  typeof useGetAllInstructorsLazyQuery
>;
export type GetAllInstructorsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllInstructorsSuspenseQuery
>;
export type GetAllInstructorsQueryResult = Apollo.QueryResult<
  GetAllInstructorsQuery,
  GetAllInstructorsQueryVariables
>;
export const UpdatedOnlineInstructorDocument = gql`
  subscription UpdatedOnlineInstructor {
    updatedOnlineInstructor {
      id
      user {
        id
        name
        avatar {
          id
          uri
        }
      }
      introduction
      job
      qualificationNumber
      educations {
        id
        school
        major
        createdAt
        updatedAt
      }
      experiences {
        id
        place
        job
        duration
        createdAt
        updatedAt
      }
      online
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useUpdatedOnlineInstructorSubscription__
 *
 * To run a query within a React component, call `useUpdatedOnlineInstructorSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedOnlineInstructorSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedOnlineInstructorSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUpdatedOnlineInstructorSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UpdatedOnlineInstructorSubscription,
    UpdatedOnlineInstructorSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UpdatedOnlineInstructorSubscription,
    UpdatedOnlineInstructorSubscriptionVariables
  >(UpdatedOnlineInstructorDocument, options);
}
export type UpdatedOnlineInstructorSubscriptionHookResult = ReturnType<
  typeof useUpdatedOnlineInstructorSubscription
>;
export type UpdatedOnlineInstructorSubscriptionResult =
  Apollo.SubscriptionResult<UpdatedOnlineInstructorSubscription>;
export const GetInvitationDocument = gql`
  query GetInvitation($invitationId: ID!) {
    getInvitation(invitationId: $invitationId) {
      id
      sender {
        id
        usuableTickets
        isSubscriber
      }
      receiver {
        id
        user {
          id
          name
        }
        online
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      userPass {
        id
      }
      userSubscription {
        id
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useGetInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(
    GetInvitationDocument,
    options
  );
}
export function useGetInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(
    GetInvitationDocument,
    options
  );
}
export function useGetInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInvitationQuery,
    GetInvitationQueryVariables
  >(GetInvitationDocument, options);
}
export type GetInvitationQueryHookResult = ReturnType<
  typeof useGetInvitationQuery
>;
export type GetInvitationLazyQueryHookResult = ReturnType<
  typeof useGetInvitationLazyQuery
>;
export type GetInvitationSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitationSuspenseQuery
>;
export type GetInvitationQueryResult = Apollo.QueryResult<
  GetInvitationQuery,
  GetInvitationQueryVariables
>;
export const SendInvitationDocument = gql`
  mutation SendInvitation(
    $receiverId: ID!
    $userPassId: ID
    $userSubscriptionId: ID
  ) {
    sendInvitation(
      receiverId: $receiverId
      userPassId: $userPassId
      userSubscriptionId: $userSubscriptionId
    ) {
      id
      sender {
        id
        usuableTickets
        isSubscriber
      }
      receiver {
        id
        user {
          id
          name
        }
        online
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      userPass {
        id
      }
      userSubscription {
        id
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;
export type SendInvitationMutationFn = Apollo.MutationFunction<
  SendInvitationMutation,
  SendInvitationMutationVariables
>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *      userPassId: // value for 'userPassId'
 *      userSubscriptionId: // value for 'userSubscriptionId'
 *   },
 * });
 */
export function useSendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationMutation,
    SendInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendInvitationMutation,
    SendInvitationMutationVariables
  >(SendInvitationDocument, options);
}
export type SendInvitationMutationHookResult = ReturnType<
  typeof useSendInvitationMutation
>;
export type SendInvitationMutationResult =
  Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationMutation,
  SendInvitationMutationVariables
>;
export const CancelMyPendingInvitationDocument = gql`
  mutation CancelMyPendingInvitation($invitationId: ID!) {
    cancelMyPendingInvitation(invitationId: $invitationId) {
      id
      sender {
        id
        usuableTickets
        isSubscriber
      }
      receiver {
        id
        user {
          id
          name
        }
        online
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      userPass {
        id
      }
      userSubscription {
        id
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;
export type CancelMyPendingInvitationMutationFn = Apollo.MutationFunction<
  CancelMyPendingInvitationMutation,
  CancelMyPendingInvitationMutationVariables
>;

/**
 * __useCancelMyPendingInvitationMutation__
 *
 * To run a mutation, you first call `useCancelMyPendingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMyPendingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMyPendingInvitationMutation, { data, loading, error }] = useCancelMyPendingInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useCancelMyPendingInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelMyPendingInvitationMutation,
    CancelMyPendingInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelMyPendingInvitationMutation,
    CancelMyPendingInvitationMutationVariables
  >(CancelMyPendingInvitationDocument, options);
}
export type CancelMyPendingInvitationMutationHookResult = ReturnType<
  typeof useCancelMyPendingInvitationMutation
>;
export type CancelMyPendingInvitationMutationResult =
  Apollo.MutationResult<CancelMyPendingInvitationMutation>;
export type CancelMyPendingInvitationMutationOptions =
  Apollo.BaseMutationOptions<
    CancelMyPendingInvitationMutation,
    CancelMyPendingInvitationMutationVariables
  >;
export const InvitationUpdatesDocument = gql`
  subscription InvitationUpdates {
    invitationUpdates {
      id
      sender {
        id
        name
      }
      receiver {
        id
      }
      session {
        id
        jwt
        startAt
        endAt
        report
        status
      }
      status
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

/**
 * __useInvitationUpdatesSubscription__
 *
 * To run a query within a React component, call `useInvitationUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvitationUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationUpdatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useInvitationUpdatesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    InvitationUpdatesSubscription,
    InvitationUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    InvitationUpdatesSubscription,
    InvitationUpdatesSubscriptionVariables
  >(InvitationUpdatesDocument, options);
}
export type InvitationUpdatesSubscriptionHookResult = ReturnType<
  typeof useInvitationUpdatesSubscription
>;
export type InvitationUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<InvitationUpdatesSubscription>;
export const GetPassesByLanguageDocument = gql`
  query GetPassesByLanguage($language: String!) {
    getPassesByLanguage(language: $language) {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      active
      createdAt
      updatedAt
    }
    getSubscriptionPlansByLanguage(language: $language) {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      duration
      freeTrial
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetPassesByLanguageQuery__
 *
 * To run a query within a React component, call `useGetPassesByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassesByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassesByLanguageQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetPassesByLanguageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPassesByLanguageQuery,
    GetPassesByLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPassesByLanguageQuery,
    GetPassesByLanguageQueryVariables
  >(GetPassesByLanguageDocument, options);
}
export function useGetPassesByLanguageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPassesByLanguageQuery,
    GetPassesByLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPassesByLanguageQuery,
    GetPassesByLanguageQueryVariables
  >(GetPassesByLanguageDocument, options);
}
export function useGetPassesByLanguageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPassesByLanguageQuery,
    GetPassesByLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPassesByLanguageQuery,
    GetPassesByLanguageQueryVariables
  >(GetPassesByLanguageDocument, options);
}
export type GetPassesByLanguageQueryHookResult = ReturnType<
  typeof useGetPassesByLanguageQuery
>;
export type GetPassesByLanguageLazyQueryHookResult = ReturnType<
  typeof useGetPassesByLanguageLazyQuery
>;
export type GetPassesByLanguageSuspenseQueryHookResult = ReturnType<
  typeof useGetPassesByLanguageSuspenseQuery
>;
export type GetPassesByLanguageQueryResult = Apollo.QueryResult<
  GetPassesByLanguageQuery,
  GetPassesByLanguageQueryVariables
>;
export const GetPassDocument = gql`
  query GetPass($passId: ID!) {
    getPass(passId: $passId) {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetPassQuery__
 *
 * To run a query within a React component, call `useGetPassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassQuery({
 *   variables: {
 *      passId: // value for 'passId'
 *   },
 * });
 */
export function useGetPassQuery(
  baseOptions: Apollo.QueryHookOptions<GetPassQuery, GetPassQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPassQuery, GetPassQueryVariables>(
    GetPassDocument,
    options
  );
}
export function useGetPassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPassQuery, GetPassQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPassQuery, GetPassQueryVariables>(
    GetPassDocument,
    options
  );
}
export function useGetPassSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPassQuery,
    GetPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPassQuery, GetPassQueryVariables>(
    GetPassDocument,
    options
  );
}
export type GetPassQueryHookResult = ReturnType<typeof useGetPassQuery>;
export type GetPassLazyQueryHookResult = ReturnType<typeof useGetPassLazyQuery>;
export type GetPassSuspenseQueryHookResult = ReturnType<
  typeof useGetPassSuspenseQuery
>;
export type GetPassQueryResult = Apollo.QueryResult<
  GetPassQuery,
  GetPassQueryVariables
>;
export const GetSubscriptionPlanDocument = gql`
  query GetSubscriptionPlan($planId: ID!) {
    getSubscriptionPlan(planId: $planId) {
      id
      language
      name
      summary
      description
      initialPrice
      finalPrice
      tickets
      duration
      freeTrial
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetSubscriptionPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >(GetSubscriptionPlanDocument, options);
}
export function useGetSubscriptionPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >(GetSubscriptionPlanDocument, options);
}
export function useGetSubscriptionPlanSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSubscriptionPlanQuery,
    GetSubscriptionPlanQueryVariables
  >(GetSubscriptionPlanDocument, options);
}
export type GetSubscriptionPlanQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlanQuery
>;
export type GetSubscriptionPlanLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlanLazyQuery
>;
export type GetSubscriptionPlanSuspenseQueryHookResult = ReturnType<
  typeof useGetSubscriptionPlanSuspenseQuery
>;
export type GetSubscriptionPlanQueryResult = Apollo.QueryResult<
  GetSubscriptionPlanQuery,
  GetSubscriptionPlanQueryVariables
>;
export const GetLanguageOptionsDocument = gql`
  query GetLanguageOptions($language: String) {
    getLanguageOptions(language: $language) {
      id
      language
      name
      active
      order
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetLanguageOptionsQuery__
 *
 * To run a query within a React component, call `useGetLanguageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguageOptionsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetLanguageOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export function useGetLanguageOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export function useGetLanguageOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLanguageOptionsQuery,
    GetLanguageOptionsQueryVariables
  >(GetLanguageOptionsDocument, options);
}
export type GetLanguageOptionsQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsQuery
>;
export type GetLanguageOptionsLazyQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsLazyQuery
>;
export type GetLanguageOptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetLanguageOptionsSuspenseQuery
>;
export type GetLanguageOptionsQueryResult = Apollo.QueryResult<
  GetLanguageOptionsQuery,
  GetLanguageOptionsQueryVariables
>;
export const GetManagementDocument = gql`
  query GetManagement {
    getManagement {
      id
      companyName
      address
      copyright
      email
      ceoName
      registrationNumber
      escroRegistration
      phone
      kakaoAddress
      sessionDuration
      shopAddress
      paymentWarning
      paymentInformation
    }
  }
`;

/**
 * __useGetManagementQuery__
 *
 * To run a query within a React component, call `useGetManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManagementQuery, GetManagementQueryVariables>(
    GetManagementDocument,
    options
  );
}
export function useGetManagementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManagementQuery, GetManagementQueryVariables>(
    GetManagementDocument,
    options
  );
}
export function useGetManagementSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetManagementQuery,
    GetManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetManagementQuery,
    GetManagementQueryVariables
  >(GetManagementDocument, options);
}
export type GetManagementQueryHookResult = ReturnType<
  typeof useGetManagementQuery
>;
export type GetManagementLazyQueryHookResult = ReturnType<
  typeof useGetManagementLazyQuery
>;
export type GetManagementSuspenseQueryHookResult = ReturnType<
  typeof useGetManagementSuspenseQuery
>;
export type GetManagementQueryResult = Apollo.QueryResult<
  GetManagementQuery,
  GetManagementQueryVariables
>;
export const GetNationalitiesDocument = gql`
  query GetNationalities {
    getNationalities {
      id
      name
      emoji
      unicode
      imageUrl
    }
  }
`;

/**
 * __useGetNationalitiesQuery__
 *
 * To run a query within a React component, call `useGetNationalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNationalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNationalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNationalitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNationalitiesQuery, GetNationalitiesQueryVariables>(
    GetNationalitiesDocument,
    options
  );
}
export function useGetNationalitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >(GetNationalitiesDocument, options);
}
export function useGetNationalitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetNationalitiesQuery,
    GetNationalitiesQueryVariables
  >(GetNationalitiesDocument, options);
}
export type GetNationalitiesQueryHookResult = ReturnType<
  typeof useGetNationalitiesQuery
>;
export type GetNationalitiesLazyQueryHookResult = ReturnType<
  typeof useGetNationalitiesLazyQuery
>;
export type GetNationalitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetNationalitiesSuspenseQuery
>;
export type GetNationalitiesQueryResult = Apollo.QueryResult<
  GetNationalitiesQuery,
  GetNationalitiesQueryVariables
>;
export const GetMyOwnUserPassesDocument = gql`
  query GetMyOwnUserPasses(
    $status: [UserPassStatus]
    $first: Int
    $after: String
  ) {
    getMyOwnUserPasses(status: $status, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        purchaser {
          id
        }
        user {
          id
        }
        pass {
          id
          language
          name
          summary
          description
          initialPrice
          finalPrice
          tickets
          active
          createdAt
          updatedAt
        }
        sent
        code
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetMyOwnUserPassesQuery__
 *
 * To run a query within a React component, call `useGetMyOwnUserPassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOwnUserPassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOwnUserPassesQuery({
 *   variables: {
 *      status: // value for 'status'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMyOwnUserPassesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyOwnUserPassesQuery,
    GetMyOwnUserPassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyOwnUserPassesQuery,
    GetMyOwnUserPassesQueryVariables
  >(GetMyOwnUserPassesDocument, options);
}
export function useGetMyOwnUserPassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOwnUserPassesQuery,
    GetMyOwnUserPassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyOwnUserPassesQuery,
    GetMyOwnUserPassesQueryVariables
  >(GetMyOwnUserPassesDocument, options);
}
export function useGetMyOwnUserPassesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyOwnUserPassesQuery,
    GetMyOwnUserPassesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyOwnUserPassesQuery,
    GetMyOwnUserPassesQueryVariables
  >(GetMyOwnUserPassesDocument, options);
}
export type GetMyOwnUserPassesQueryHookResult = ReturnType<
  typeof useGetMyOwnUserPassesQuery
>;
export type GetMyOwnUserPassesLazyQueryHookResult = ReturnType<
  typeof useGetMyOwnUserPassesLazyQuery
>;
export type GetMyOwnUserPassesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyOwnUserPassesSuspenseQuery
>;
export type GetMyOwnUserPassesQueryResult = Apollo.QueryResult<
  GetMyOwnUserPassesQuery,
  GetMyOwnUserPassesQueryVariables
>;
export const ReceiveUserPassDocument = gql`
  mutation ReceiveUserPass($code: String!) {
    receiveUserPass(code: $code) {
      id
      purchaser {
        id
      }
      user {
        id
      }
      pass {
        id
        language
        name
        summary
        description
        initialPrice
        finalPrice
        tickets
        active
        createdAt
        updatedAt
      }
      sent
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export type ReceiveUserPassMutationFn = Apollo.MutationFunction<
  ReceiveUserPassMutation,
  ReceiveUserPassMutationVariables
>;

/**
 * __useReceiveUserPassMutation__
 *
 * To run a mutation, you first call `useReceiveUserPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveUserPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveUserPassMutation, { data, loading, error }] = useReceiveUserPassMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useReceiveUserPassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReceiveUserPassMutation,
    ReceiveUserPassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReceiveUserPassMutation,
    ReceiveUserPassMutationVariables
  >(ReceiveUserPassDocument, options);
}
export type ReceiveUserPassMutationHookResult = ReturnType<
  typeof useReceiveUserPassMutation
>;
export type ReceiveUserPassMutationResult =
  Apollo.MutationResult<ReceiveUserPassMutation>;
export type ReceiveUserPassMutationOptions = Apollo.BaseMutationOptions<
  ReceiveUserPassMutation,
  ReceiveUserPassMutationVariables
>;
export const SendUserPassDocument = gql`
  mutation SendUserPass($userPassId: ID!, $name: String!, $phone: String!) {
    sendUserPass(userPassId: $userPassId, name: $name, phone: $phone) {
      id
      purchaser {
        id
      }
      user {
        id
      }
      pass {
        id
        language
        name
        summary
        description
        initialPrice
        finalPrice
        tickets
        active
        createdAt
        updatedAt
      }
      code
      status
      sent
      createdAt
      updatedAt
    }
  }
`;
export type SendUserPassMutationFn = Apollo.MutationFunction<
  SendUserPassMutation,
  SendUserPassMutationVariables
>;

/**
 * __useSendUserPassMutation__
 *
 * To run a mutation, you first call `useSendUserPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserPassMutation, { data, loading, error }] = useSendUserPassMutation({
 *   variables: {
 *      userPassId: // value for 'userPassId'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSendUserPassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendUserPassMutation,
    SendUserPassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendUserPassMutation,
    SendUserPassMutationVariables
  >(SendUserPassDocument, options);
}
export type SendUserPassMutationHookResult = ReturnType<
  typeof useSendUserPassMutation
>;
export type SendUserPassMutationResult =
  Apollo.MutationResult<SendUserPassMutation>;
export type SendUserPassMutationOptions = Apollo.BaseMutationOptions<
  SendUserPassMutation,
  SendUserPassMutationVariables
>;
export const GetMyChronicDiseasesDocument = gql`
  query GetMyChronicDiseases {
    getMyChronicDiseases {
      id
      user {
        id
      }
      type {
        id
        name
      }
      etc
      medicine
      firstAid
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyChronicDiseasesQuery__
 *
 * To run a query within a React component, call `useGetMyChronicDiseasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyChronicDiseasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyChronicDiseasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyChronicDiseasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyChronicDiseasesQuery,
    GetMyChronicDiseasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyChronicDiseasesQuery,
    GetMyChronicDiseasesQueryVariables
  >(GetMyChronicDiseasesDocument, options);
}
export function useGetMyChronicDiseasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyChronicDiseasesQuery,
    GetMyChronicDiseasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyChronicDiseasesQuery,
    GetMyChronicDiseasesQueryVariables
  >(GetMyChronicDiseasesDocument, options);
}
export function useGetMyChronicDiseasesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyChronicDiseasesQuery,
    GetMyChronicDiseasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyChronicDiseasesQuery,
    GetMyChronicDiseasesQueryVariables
  >(GetMyChronicDiseasesDocument, options);
}
export type GetMyChronicDiseasesQueryHookResult = ReturnType<
  typeof useGetMyChronicDiseasesQuery
>;
export type GetMyChronicDiseasesLazyQueryHookResult = ReturnType<
  typeof useGetMyChronicDiseasesLazyQuery
>;
export type GetMyChronicDiseasesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyChronicDiseasesSuspenseQuery
>;
export type GetMyChronicDiseasesQueryResult = Apollo.QueryResult<
  GetMyChronicDiseasesQuery,
  GetMyChronicDiseasesQueryVariables
>;
export const GetMyAllergiesDocument = gql`
  query GetMyAllergies {
    getMyAllergies {
      id
      user {
        id
      }
      type {
        id
        name
      }
      summary
      firstAid
      symptoms {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyAllergiesQuery__
 *
 * To run a query within a React component, call `useGetMyAllergiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAllergiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAllergiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAllergiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyAllergiesQuery,
    GetMyAllergiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyAllergiesQuery, GetMyAllergiesQueryVariables>(
    GetMyAllergiesDocument,
    options
  );
}
export function useGetMyAllergiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyAllergiesQuery,
    GetMyAllergiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyAllergiesQuery, GetMyAllergiesQueryVariables>(
    GetMyAllergiesDocument,
    options
  );
}
export function useGetMyAllergiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyAllergiesQuery,
    GetMyAllergiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyAllergiesQuery,
    GetMyAllergiesQueryVariables
  >(GetMyAllergiesDocument, options);
}
export type GetMyAllergiesQueryHookResult = ReturnType<
  typeof useGetMyAllergiesQuery
>;
export type GetMyAllergiesLazyQueryHookResult = ReturnType<
  typeof useGetMyAllergiesLazyQuery
>;
export type GetMyAllergiesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyAllergiesSuspenseQuery
>;
export type GetMyAllergiesQueryResult = Apollo.QueryResult<
  GetMyAllergiesQuery,
  GetMyAllergiesQueryVariables
>;
export const GetMyMedicinesDocument = gql`
  query GetMyMedicines {
    getMyMedicines {
      id
      user {
        id
      }
      medicine
      dosage
      description
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyMedicinesQuery__
 *
 * To run a query within a React component, call `useGetMyMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyMedicinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyMedicinesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyMedicinesQuery,
    GetMyMedicinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyMedicinesQuery, GetMyMedicinesQueryVariables>(
    GetMyMedicinesDocument,
    options
  );
}
export function useGetMyMedicinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyMedicinesQuery,
    GetMyMedicinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyMedicinesQuery, GetMyMedicinesQueryVariables>(
    GetMyMedicinesDocument,
    options
  );
}
export function useGetMyMedicinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyMedicinesQuery,
    GetMyMedicinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyMedicinesQuery,
    GetMyMedicinesQueryVariables
  >(GetMyMedicinesDocument, options);
}
export type GetMyMedicinesQueryHookResult = ReturnType<
  typeof useGetMyMedicinesQuery
>;
export type GetMyMedicinesLazyQueryHookResult = ReturnType<
  typeof useGetMyMedicinesLazyQuery
>;
export type GetMyMedicinesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyMedicinesSuspenseQuery
>;
export type GetMyMedicinesQueryResult = Apollo.QueryResult<
  GetMyMedicinesQuery,
  GetMyMedicinesQueryVariables
>;
export const GetMyVaccinesDocument = gql`
  query GetMyVaccines {
    getMyVaccines {
      id
      user {
        id
      }
      vaccineType {
        id
        name
      }
      level
      lastVaccinatedAt
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyVaccinesQuery__
 *
 * To run a query within a React component, call `useGetMyVaccinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVaccinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVaccinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyVaccinesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyVaccinesQuery,
    GetMyVaccinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyVaccinesQuery, GetMyVaccinesQueryVariables>(
    GetMyVaccinesDocument,
    options
  );
}
export function useGetMyVaccinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyVaccinesQuery,
    GetMyVaccinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyVaccinesQuery, GetMyVaccinesQueryVariables>(
    GetMyVaccinesDocument,
    options
  );
}
export function useGetMyVaccinesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyVaccinesQuery,
    GetMyVaccinesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyVaccinesQuery,
    GetMyVaccinesQueryVariables
  >(GetMyVaccinesDocument, options);
}
export type GetMyVaccinesQueryHookResult = ReturnType<
  typeof useGetMyVaccinesQuery
>;
export type GetMyVaccinesLazyQueryHookResult = ReturnType<
  typeof useGetMyVaccinesLazyQuery
>;
export type GetMyVaccinesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyVaccinesSuspenseQuery
>;
export type GetMyVaccinesQueryResult = Apollo.QueryResult<
  GetMyVaccinesQuery,
  GetMyVaccinesQueryVariables
>;
export const GetMySurgerysDocument = gql`
  query GetMySurgerys {
    getMySurgerys {
      id
      user {
        id
      }
      surgery
      part
      department
      media {
        id
        uri
        name
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMySurgerysQuery__
 *
 * To run a query within a React component, call `useGetMySurgerysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySurgerysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySurgerysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySurgerysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySurgerysQuery,
    GetMySurgerysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMySurgerysQuery, GetMySurgerysQueryVariables>(
    GetMySurgerysDocument,
    options
  );
}
export function useGetMySurgerysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySurgerysQuery,
    GetMySurgerysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMySurgerysQuery, GetMySurgerysQueryVariables>(
    GetMySurgerysDocument,
    options
  );
}
export function useGetMySurgerysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMySurgerysQuery,
    GetMySurgerysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMySurgerysQuery,
    GetMySurgerysQueryVariables
  >(GetMySurgerysDocument, options);
}
export type GetMySurgerysQueryHookResult = ReturnType<
  typeof useGetMySurgerysQuery
>;
export type GetMySurgerysLazyQueryHookResult = ReturnType<
  typeof useGetMySurgerysLazyQuery
>;
export type GetMySurgerysSuspenseQueryHookResult = ReturnType<
  typeof useGetMySurgerysSuspenseQuery
>;
export type GetMySurgerysQueryResult = Apollo.QueryResult<
  GetMySurgerysQuery,
  GetMySurgerysQueryVariables
>;
export const GetMyInsurancesDocument = gql`
  query GetMyInsurances {
    getMyInsurances {
      id
      user {
        id
      }
      type
      name
      coverage
      memberId
      groupId
      companyNumber
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyInsurancesQuery__
 *
 * To run a query within a React component, call `useGetMyInsurancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInsurancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInsurancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInsurancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyInsurancesQuery,
    GetMyInsurancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyInsurancesQuery, GetMyInsurancesQueryVariables>(
    GetMyInsurancesDocument,
    options
  );
}
export function useGetMyInsurancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyInsurancesQuery,
    GetMyInsurancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyInsurancesQuery,
    GetMyInsurancesQueryVariables
  >(GetMyInsurancesDocument, options);
}
export function useGetMyInsurancesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyInsurancesQuery,
    GetMyInsurancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyInsurancesQuery,
    GetMyInsurancesQueryVariables
  >(GetMyInsurancesDocument, options);
}
export type GetMyInsurancesQueryHookResult = ReturnType<
  typeof useGetMyInsurancesQuery
>;
export type GetMyInsurancesLazyQueryHookResult = ReturnType<
  typeof useGetMyInsurancesLazyQuery
>;
export type GetMyInsurancesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyInsurancesSuspenseQuery
>;
export type GetMyInsurancesQueryResult = Apollo.QueryResult<
  GetMyInsurancesQuery,
  GetMyInsurancesQueryVariables
>;
export const UpdateMyChronicDiseaseDocument = gql`
  mutation UpdateMyChronicDisease(
    $userChronicDiseaseInput: UserChronicDiseaseInput!
  ) {
    updateMyChronicDisease(userChronicDiseaseInput: $userChronicDiseaseInput) {
      id
      user {
        id
      }
      type {
        id
        name
      }
      etc
      medicine
      firstAid
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyChronicDiseaseMutationFn = Apollo.MutationFunction<
  UpdateMyChronicDiseaseMutation,
  UpdateMyChronicDiseaseMutationVariables
>;

/**
 * __useUpdateMyChronicDiseaseMutation__
 *
 * To run a mutation, you first call `useUpdateMyChronicDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyChronicDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyChronicDiseaseMutation, { data, loading, error }] = useUpdateMyChronicDiseaseMutation({
 *   variables: {
 *      userChronicDiseaseInput: // value for 'userChronicDiseaseInput'
 *   },
 * });
 */
export function useUpdateMyChronicDiseaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyChronicDiseaseMutation,
    UpdateMyChronicDiseaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyChronicDiseaseMutation,
    UpdateMyChronicDiseaseMutationVariables
  >(UpdateMyChronicDiseaseDocument, options);
}
export type UpdateMyChronicDiseaseMutationHookResult = ReturnType<
  typeof useUpdateMyChronicDiseaseMutation
>;
export type UpdateMyChronicDiseaseMutationResult =
  Apollo.MutationResult<UpdateMyChronicDiseaseMutation>;
export type UpdateMyChronicDiseaseMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyChronicDiseaseMutation,
  UpdateMyChronicDiseaseMutationVariables
>;
export const UpdateMyAllergyDocument = gql`
  mutation UpdateMyAllergy($userAllergyInput: UserAllergyInput!) {
    updateMyAllergy(userAllergyInput: $userAllergyInput) {
      id
      user {
        id
      }
      type {
        id
        name
      }
      summary
      firstAid
      symptoms {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyAllergyMutationFn = Apollo.MutationFunction<
  UpdateMyAllergyMutation,
  UpdateMyAllergyMutationVariables
>;

/**
 * __useUpdateMyAllergyMutation__
 *
 * To run a mutation, you first call `useUpdateMyAllergyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyAllergyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyAllergyMutation, { data, loading, error }] = useUpdateMyAllergyMutation({
 *   variables: {
 *      userAllergyInput: // value for 'userAllergyInput'
 *   },
 * });
 */
export function useUpdateMyAllergyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyAllergyMutation,
    UpdateMyAllergyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyAllergyMutation,
    UpdateMyAllergyMutationVariables
  >(UpdateMyAllergyDocument, options);
}
export type UpdateMyAllergyMutationHookResult = ReturnType<
  typeof useUpdateMyAllergyMutation
>;
export type UpdateMyAllergyMutationResult =
  Apollo.MutationResult<UpdateMyAllergyMutation>;
export type UpdateMyAllergyMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyAllergyMutation,
  UpdateMyAllergyMutationVariables
>;
export const UpdateMyMedicineDocument = gql`
  mutation UpdateMyMedicine($userMedicineInput: UserMedicineInput!) {
    updateMyMedicine(userMedicineInput: $userMedicineInput) {
      id
      user {
        id
      }
      medicine
      dosage
      description
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyMedicineMutationFn = Apollo.MutationFunction<
  UpdateMyMedicineMutation,
  UpdateMyMedicineMutationVariables
>;

/**
 * __useUpdateMyMedicineMutation__
 *
 * To run a mutation, you first call `useUpdateMyMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyMedicineMutation, { data, loading, error }] = useUpdateMyMedicineMutation({
 *   variables: {
 *      userMedicineInput: // value for 'userMedicineInput'
 *   },
 * });
 */
export function useUpdateMyMedicineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyMedicineMutation,
    UpdateMyMedicineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyMedicineMutation,
    UpdateMyMedicineMutationVariables
  >(UpdateMyMedicineDocument, options);
}
export type UpdateMyMedicineMutationHookResult = ReturnType<
  typeof useUpdateMyMedicineMutation
>;
export type UpdateMyMedicineMutationResult =
  Apollo.MutationResult<UpdateMyMedicineMutation>;
export type UpdateMyMedicineMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyMedicineMutation,
  UpdateMyMedicineMutationVariables
>;
export const UpdateMyVaccineDocument = gql`
  mutation UpdateMyVaccine($userVaccineInput: UserVaccineInput!) {
    updateMyVaccine(userVaccineInput: $userVaccineInput) {
      id
      user {
        id
      }
      vaccineType {
        id
        name
      }
      level
      lastVaccinatedAt
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyVaccineMutationFn = Apollo.MutationFunction<
  UpdateMyVaccineMutation,
  UpdateMyVaccineMutationVariables
>;

/**
 * __useUpdateMyVaccineMutation__
 *
 * To run a mutation, you first call `useUpdateMyVaccineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyVaccineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyVaccineMutation, { data, loading, error }] = useUpdateMyVaccineMutation({
 *   variables: {
 *      userVaccineInput: // value for 'userVaccineInput'
 *   },
 * });
 */
export function useUpdateMyVaccineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyVaccineMutation,
    UpdateMyVaccineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyVaccineMutation,
    UpdateMyVaccineMutationVariables
  >(UpdateMyVaccineDocument, options);
}
export type UpdateMyVaccineMutationHookResult = ReturnType<
  typeof useUpdateMyVaccineMutation
>;
export type UpdateMyVaccineMutationResult =
  Apollo.MutationResult<UpdateMyVaccineMutation>;
export type UpdateMyVaccineMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyVaccineMutation,
  UpdateMyVaccineMutationVariables
>;
export const UpdateMySurgeryDocument = gql`
  mutation UpdateMySurgery($userSurgeryInput: UserSurgeryInput!) {
    updateMySurgery(userSurgeryInput: $userSurgeryInput) {
      id
      user {
        id
      }
      surgery
      part
      department
      media {
        id
        uri
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMySurgeryMutationFn = Apollo.MutationFunction<
  UpdateMySurgeryMutation,
  UpdateMySurgeryMutationVariables
>;

/**
 * __useUpdateMySurgeryMutation__
 *
 * To run a mutation, you first call `useUpdateMySurgeryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMySurgeryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMySurgeryMutation, { data, loading, error }] = useUpdateMySurgeryMutation({
 *   variables: {
 *      userSurgeryInput: // value for 'userSurgeryInput'
 *   },
 * });
 */
export function useUpdateMySurgeryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMySurgeryMutation,
    UpdateMySurgeryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMySurgeryMutation,
    UpdateMySurgeryMutationVariables
  >(UpdateMySurgeryDocument, options);
}
export type UpdateMySurgeryMutationHookResult = ReturnType<
  typeof useUpdateMySurgeryMutation
>;
export type UpdateMySurgeryMutationResult =
  Apollo.MutationResult<UpdateMySurgeryMutation>;
export type UpdateMySurgeryMutationOptions = Apollo.BaseMutationOptions<
  UpdateMySurgeryMutation,
  UpdateMySurgeryMutationVariables
>;
export const UpdateMyInsuranceDocument = gql`
  mutation UpdateMyInsurance($userInsuranceInput: UserInsuranceInput!) {
    updateMyInsurance(userInsuranceInput: $userInsuranceInput) {
      id
      user {
        id
      }
      type
      name
      coverage
      memberId
      groupId
      companyNumber
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyInsuranceMutationFn = Apollo.MutationFunction<
  UpdateMyInsuranceMutation,
  UpdateMyInsuranceMutationVariables
>;

/**
 * __useUpdateMyInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateMyInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyInsuranceMutation, { data, loading, error }] = useUpdateMyInsuranceMutation({
 *   variables: {
 *      userInsuranceInput: // value for 'userInsuranceInput'
 *   },
 * });
 */
export function useUpdateMyInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyInsuranceMutation,
    UpdateMyInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyInsuranceMutation,
    UpdateMyInsuranceMutationVariables
  >(UpdateMyInsuranceDocument, options);
}
export type UpdateMyInsuranceMutationHookResult = ReturnType<
  typeof useUpdateMyInsuranceMutation
>;
export type UpdateMyInsuranceMutationResult =
  Apollo.MutationResult<UpdateMyInsuranceMutation>;
export type UpdateMyInsuranceMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyInsuranceMutation,
  UpdateMyInsuranceMutationVariables
>;
export const DeleteMyChronicDiseaseDocument = gql`
  mutation DeleteMyChronicDisease($userChronicDiseaseId: ID!) {
    deleteMyChronicDisease(userChronicDiseaseId: $userChronicDiseaseId)
  }
`;
export type DeleteMyChronicDiseaseMutationFn = Apollo.MutationFunction<
  DeleteMyChronicDiseaseMutation,
  DeleteMyChronicDiseaseMutationVariables
>;

/**
 * __useDeleteMyChronicDiseaseMutation__
 *
 * To run a mutation, you first call `useDeleteMyChronicDiseaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyChronicDiseaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyChronicDiseaseMutation, { data, loading, error }] = useDeleteMyChronicDiseaseMutation({
 *   variables: {
 *      userChronicDiseaseId: // value for 'userChronicDiseaseId'
 *   },
 * });
 */
export function useDeleteMyChronicDiseaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyChronicDiseaseMutation,
    DeleteMyChronicDiseaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyChronicDiseaseMutation,
    DeleteMyChronicDiseaseMutationVariables
  >(DeleteMyChronicDiseaseDocument, options);
}
export type DeleteMyChronicDiseaseMutationHookResult = ReturnType<
  typeof useDeleteMyChronicDiseaseMutation
>;
export type DeleteMyChronicDiseaseMutationResult =
  Apollo.MutationResult<DeleteMyChronicDiseaseMutation>;
export type DeleteMyChronicDiseaseMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyChronicDiseaseMutation,
  DeleteMyChronicDiseaseMutationVariables
>;
export const DeleteMyAllergyDocument = gql`
  mutation DeleteMyAllergy($userAllergyId: ID!) {
    deleteMyAllergy(userAllergyId: $userAllergyId)
  }
`;
export type DeleteMyAllergyMutationFn = Apollo.MutationFunction<
  DeleteMyAllergyMutation,
  DeleteMyAllergyMutationVariables
>;

/**
 * __useDeleteMyAllergyMutation__
 *
 * To run a mutation, you first call `useDeleteMyAllergyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyAllergyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyAllergyMutation, { data, loading, error }] = useDeleteMyAllergyMutation({
 *   variables: {
 *      userAllergyId: // value for 'userAllergyId'
 *   },
 * });
 */
export function useDeleteMyAllergyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyAllergyMutation,
    DeleteMyAllergyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyAllergyMutation,
    DeleteMyAllergyMutationVariables
  >(DeleteMyAllergyDocument, options);
}
export type DeleteMyAllergyMutationHookResult = ReturnType<
  typeof useDeleteMyAllergyMutation
>;
export type DeleteMyAllergyMutationResult =
  Apollo.MutationResult<DeleteMyAllergyMutation>;
export type DeleteMyAllergyMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyAllergyMutation,
  DeleteMyAllergyMutationVariables
>;
export const DeleteMyMedicineDocument = gql`
  mutation DeleteMyMedicine($userMedicineId: ID!) {
    deleteMyMedicine(userMedicineId: $userMedicineId)
  }
`;
export type DeleteMyMedicineMutationFn = Apollo.MutationFunction<
  DeleteMyMedicineMutation,
  DeleteMyMedicineMutationVariables
>;

/**
 * __useDeleteMyMedicineMutation__
 *
 * To run a mutation, you first call `useDeleteMyMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyMedicineMutation, { data, loading, error }] = useDeleteMyMedicineMutation({
 *   variables: {
 *      userMedicineId: // value for 'userMedicineId'
 *   },
 * });
 */
export function useDeleteMyMedicineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyMedicineMutation,
    DeleteMyMedicineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyMedicineMutation,
    DeleteMyMedicineMutationVariables
  >(DeleteMyMedicineDocument, options);
}
export type DeleteMyMedicineMutationHookResult = ReturnType<
  typeof useDeleteMyMedicineMutation
>;
export type DeleteMyMedicineMutationResult =
  Apollo.MutationResult<DeleteMyMedicineMutation>;
export type DeleteMyMedicineMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyMedicineMutation,
  DeleteMyMedicineMutationVariables
>;
export const DeleteMyVaccineDocument = gql`
  mutation DeleteMyVaccine($userVaccineId: ID!) {
    deleteMyVaccine(userVaccineId: $userVaccineId)
  }
`;
export type DeleteMyVaccineMutationFn = Apollo.MutationFunction<
  DeleteMyVaccineMutation,
  DeleteMyVaccineMutationVariables
>;

/**
 * __useDeleteMyVaccineMutation__
 *
 * To run a mutation, you first call `useDeleteMyVaccineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyVaccineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyVaccineMutation, { data, loading, error }] = useDeleteMyVaccineMutation({
 *   variables: {
 *      userVaccineId: // value for 'userVaccineId'
 *   },
 * });
 */
export function useDeleteMyVaccineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyVaccineMutation,
    DeleteMyVaccineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyVaccineMutation,
    DeleteMyVaccineMutationVariables
  >(DeleteMyVaccineDocument, options);
}
export type DeleteMyVaccineMutationHookResult = ReturnType<
  typeof useDeleteMyVaccineMutation
>;
export type DeleteMyVaccineMutationResult =
  Apollo.MutationResult<DeleteMyVaccineMutation>;
export type DeleteMyVaccineMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyVaccineMutation,
  DeleteMyVaccineMutationVariables
>;
export const DeleteMySurgeryDocument = gql`
  mutation DeleteMySurgery($userSurgeryId: ID!) {
    deleteMySurgery(userSurgeryId: $userSurgeryId)
  }
`;
export type DeleteMySurgeryMutationFn = Apollo.MutationFunction<
  DeleteMySurgeryMutation,
  DeleteMySurgeryMutationVariables
>;

/**
 * __useDeleteMySurgeryMutation__
 *
 * To run a mutation, you first call `useDeleteMySurgeryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMySurgeryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMySurgeryMutation, { data, loading, error }] = useDeleteMySurgeryMutation({
 *   variables: {
 *      userSurgeryId: // value for 'userSurgeryId'
 *   },
 * });
 */
export function useDeleteMySurgeryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMySurgeryMutation,
    DeleteMySurgeryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMySurgeryMutation,
    DeleteMySurgeryMutationVariables
  >(DeleteMySurgeryDocument, options);
}
export type DeleteMySurgeryMutationHookResult = ReturnType<
  typeof useDeleteMySurgeryMutation
>;
export type DeleteMySurgeryMutationResult =
  Apollo.MutationResult<DeleteMySurgeryMutation>;
export type DeleteMySurgeryMutationOptions = Apollo.BaseMutationOptions<
  DeleteMySurgeryMutation,
  DeleteMySurgeryMutationVariables
>;
export const DeleteMyInsuranceDocument = gql`
  mutation DeleteMyInsurance($userInsuranceId: ID!) {
    deleteMyInsurance(userInsuranceId: $userInsuranceId)
  }
`;
export type DeleteMyInsuranceMutationFn = Apollo.MutationFunction<
  DeleteMyInsuranceMutation,
  DeleteMyInsuranceMutationVariables
>;

/**
 * __useDeleteMyInsuranceMutation__
 *
 * To run a mutation, you first call `useDeleteMyInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyInsuranceMutation, { data, loading, error }] = useDeleteMyInsuranceMutation({
 *   variables: {
 *      userInsuranceId: // value for 'userInsuranceId'
 *   },
 * });
 */
export function useDeleteMyInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMyInsuranceMutation,
    DeleteMyInsuranceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMyInsuranceMutation,
    DeleteMyInsuranceMutationVariables
  >(DeleteMyInsuranceDocument, options);
}
export type DeleteMyInsuranceMutationHookResult = ReturnType<
  typeof useDeleteMyInsuranceMutation
>;
export type DeleteMyInsuranceMutationResult =
  Apollo.MutationResult<DeleteMyInsuranceMutation>;
export type DeleteMyInsuranceMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyInsuranceMutation,
  DeleteMyInsuranceMutationVariables
>;
export const GetChronicDiseaseTypesDocument = gql`
  query GetChronicDiseaseTypes($language: String, $activeOnly: Boolean) {
    getChronicDiseaseTypes(language: $language, activeOnly: $activeOnly) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetChronicDiseaseTypesQuery__
 *
 * To run a query within a React component, call `useGetChronicDiseaseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChronicDiseaseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChronicDiseaseTypesQuery({
 *   variables: {
 *      language: // value for 'language'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetChronicDiseaseTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChronicDiseaseTypesQuery,
    GetChronicDiseaseTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChronicDiseaseTypesQuery,
    GetChronicDiseaseTypesQueryVariables
  >(GetChronicDiseaseTypesDocument, options);
}
export function useGetChronicDiseaseTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChronicDiseaseTypesQuery,
    GetChronicDiseaseTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChronicDiseaseTypesQuery,
    GetChronicDiseaseTypesQueryVariables
  >(GetChronicDiseaseTypesDocument, options);
}
export function useGetChronicDiseaseTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetChronicDiseaseTypesQuery,
    GetChronicDiseaseTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetChronicDiseaseTypesQuery,
    GetChronicDiseaseTypesQueryVariables
  >(GetChronicDiseaseTypesDocument, options);
}
export type GetChronicDiseaseTypesQueryHookResult = ReturnType<
  typeof useGetChronicDiseaseTypesQuery
>;
export type GetChronicDiseaseTypesLazyQueryHookResult = ReturnType<
  typeof useGetChronicDiseaseTypesLazyQuery
>;
export type GetChronicDiseaseTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetChronicDiseaseTypesSuspenseQuery
>;
export type GetChronicDiseaseTypesQueryResult = Apollo.QueryResult<
  GetChronicDiseaseTypesQuery,
  GetChronicDiseaseTypesQueryVariables
>;
export const GetAllergyTypesDocument = gql`
  query GetAllergyTypes($language: String, $activeOnly: Boolean) {
    getAllergyTypes(language: $language, activeOnly: $activeOnly) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllergyTypesQuery__
 *
 * To run a query within a React component, call `useGetAllergyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergyTypesQuery({
 *   variables: {
 *      language: // value for 'language'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetAllergyTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllergyTypesQuery,
    GetAllergyTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllergyTypesQuery, GetAllergyTypesQueryVariables>(
    GetAllergyTypesDocument,
    options
  );
}
export function useGetAllergyTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllergyTypesQuery,
    GetAllergyTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllergyTypesQuery,
    GetAllergyTypesQueryVariables
  >(GetAllergyTypesDocument, options);
}
export function useGetAllergyTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllergyTypesQuery,
    GetAllergyTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllergyTypesQuery,
    GetAllergyTypesQueryVariables
  >(GetAllergyTypesDocument, options);
}
export type GetAllergyTypesQueryHookResult = ReturnType<
  typeof useGetAllergyTypesQuery
>;
export type GetAllergyTypesLazyQueryHookResult = ReturnType<
  typeof useGetAllergyTypesLazyQuery
>;
export type GetAllergyTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllergyTypesSuspenseQuery
>;
export type GetAllergyTypesQueryResult = Apollo.QueryResult<
  GetAllergyTypesQuery,
  GetAllergyTypesQueryVariables
>;
export const GetAllergySymptomsDocument = gql`
  query GetAllergySymptoms($language: String, $activeOnly: Boolean) {
    getAllergySymptoms(language: $language, activeOnly: $activeOnly) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetAllergySymptomsQuery__
 *
 * To run a query within a React component, call `useGetAllergySymptomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergySymptomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergySymptomsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetAllergySymptomsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllergySymptomsQuery,
    GetAllergySymptomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllergySymptomsQuery,
    GetAllergySymptomsQueryVariables
  >(GetAllergySymptomsDocument, options);
}
export function useGetAllergySymptomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllergySymptomsQuery,
    GetAllergySymptomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllergySymptomsQuery,
    GetAllergySymptomsQueryVariables
  >(GetAllergySymptomsDocument, options);
}
export function useGetAllergySymptomsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllergySymptomsQuery,
    GetAllergySymptomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllergySymptomsQuery,
    GetAllergySymptomsQueryVariables
  >(GetAllergySymptomsDocument, options);
}
export type GetAllergySymptomsQueryHookResult = ReturnType<
  typeof useGetAllergySymptomsQuery
>;
export type GetAllergySymptomsLazyQueryHookResult = ReturnType<
  typeof useGetAllergySymptomsLazyQuery
>;
export type GetAllergySymptomsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllergySymptomsSuspenseQuery
>;
export type GetAllergySymptomsQueryResult = Apollo.QueryResult<
  GetAllergySymptomsQuery,
  GetAllergySymptomsQueryVariables
>;
export const GetVaccineTypesDocument = gql`
  query GetVaccineTypes($language: String, $activeOnly: Boolean) {
    getVaccineTypes(language: $language, activeOnly: $activeOnly) {
      id
      language
      name
      order
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetVaccineTypesQuery__
 *
 * To run a query within a React component, call `useGetVaccineTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVaccineTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVaccineTypesQuery({
 *   variables: {
 *      language: // value for 'language'
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetVaccineTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVaccineTypesQuery,
    GetVaccineTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVaccineTypesQuery, GetVaccineTypesQueryVariables>(
    GetVaccineTypesDocument,
    options
  );
}
export function useGetVaccineTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVaccineTypesQuery,
    GetVaccineTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVaccineTypesQuery,
    GetVaccineTypesQueryVariables
  >(GetVaccineTypesDocument, options);
}
export function useGetVaccineTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetVaccineTypesQuery,
    GetVaccineTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetVaccineTypesQuery,
    GetVaccineTypesQueryVariables
  >(GetVaccineTypesDocument, options);
}
export type GetVaccineTypesQueryHookResult = ReturnType<
  typeof useGetVaccineTypesQuery
>;
export type GetVaccineTypesLazyQueryHookResult = ReturnType<
  typeof useGetVaccineTypesLazyQuery
>;
export type GetVaccineTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetVaccineTypesSuspenseQuery
>;
export type GetVaccineTypesQueryResult = Apollo.QueryResult<
  GetVaccineTypesQuery,
  GetVaccineTypesQueryVariables
>;
export const GetMySessionsDocument = gql`
  query GetMySessions($first: Int, $after: String) {
    getMySessions(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        requester {
          id
          name
          usuableTickets
        }
        instructor {
          id
          user {
            id
            name
            avatar {
              id
              uri
            }
          }
        }
        jwt
        password
        startAt
        endAt
        report
        status
        needInterpreter
        users {
          id
          user {
            id
            name
            avatar {
              id
              uri
            }
            instructor {
              id
              job
              experiences {
                id
                place
                job
              }
            }
            interpreter {
              id
              experiences {
                id
                place
                job
              }
            }
          }
          joinAt
          leaveAt
          active
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetMySessionsQuery__
 *
 * To run a query within a React component, call `useGetMySessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySessionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMySessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMySessionsQuery, GetMySessionsQueryVariables>(
    GetMySessionsDocument,
    options
  );
}
export function useGetMySessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMySessionsQuery, GetMySessionsQueryVariables>(
    GetMySessionsDocument,
    options
  );
}
export function useGetMySessionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMySessionsQuery,
    GetMySessionsQueryVariables
  >(GetMySessionsDocument, options);
}
export type GetMySessionsQueryHookResult = ReturnType<
  typeof useGetMySessionsQuery
>;
export type GetMySessionsLazyQueryHookResult = ReturnType<
  typeof useGetMySessionsLazyQuery
>;
export type GetMySessionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMySessionsSuspenseQuery
>;
export type GetMySessionsQueryResult = Apollo.QueryResult<
  GetMySessionsQuery,
  GetMySessionsQueryVariables
>;
export const GetSessionDocument = gql`
  query GetSession($sessionId: ID!) {
    getSession(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      password
      startAt
      endAt
      report
      status
      needInterpreter
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export function useGetSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSessionQuery,
    GetSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSessionQuery, GetSessionQueryVariables>(
    GetSessionDocument,
    options
  );
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<
  typeof useGetSessionLazyQuery
>;
export type GetSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetSessionSuspenseQuery
>;
export type GetSessionQueryResult = Apollo.QueryResult<
  GetSessionQuery,
  GetSessionQueryVariables
>;
export const GetMyActiveSessionDocument = gql`
  query GetMyActiveSession {
    getMyActiveSession {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyActiveSessionQuery__
 *
 * To run a query within a React component, call `useGetMyActiveSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyActiveSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyActiveSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyActiveSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >(GetMyActiveSessionDocument, options);
}
export function useGetMyActiveSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >(GetMyActiveSessionDocument, options);
}
export function useGetMyActiveSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyActiveSessionQuery,
    GetMyActiveSessionQueryVariables
  >(GetMyActiveSessionDocument, options);
}
export type GetMyActiveSessionQueryHookResult = ReturnType<
  typeof useGetMyActiveSessionQuery
>;
export type GetMyActiveSessionLazyQueryHookResult = ReturnType<
  typeof useGetMyActiveSessionLazyQuery
>;
export type GetMyActiveSessionSuspenseQueryHookResult = ReturnType<
  typeof useGetMyActiveSessionSuspenseQuery
>;
export type GetMyActiveSessionQueryResult = Apollo.QueryResult<
  GetMyActiveSessionQuery,
  GetMyActiveSessionQueryVariables
>;
export const JoinSessionDocument = gql`
  mutation JoinSession($sessionId: ID!) {
    joinSession(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export type JoinSessionMutationFn = Apollo.MutationFunction<
  JoinSessionMutation,
  JoinSessionMutationVariables
>;

/**
 * __useJoinSessionMutation__
 *
 * To run a mutation, you first call `useJoinSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSessionMutation, { data, loading, error }] = useJoinSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinSessionMutation,
    JoinSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinSessionMutation, JoinSessionMutationVariables>(
    JoinSessionDocument,
    options
  );
}
export type JoinSessionMutationHookResult = ReturnType<
  typeof useJoinSessionMutation
>;
export type JoinSessionMutationResult =
  Apollo.MutationResult<JoinSessionMutation>;
export type JoinSessionMutationOptions = Apollo.BaseMutationOptions<
  JoinSessionMutation,
  JoinSessionMutationVariables
>;
export const LeaveSessionDocument = gql`
  mutation LeaveSession($sessionId: ID!) {
    leaveSession(sessionId: $sessionId) {
      id
      startAt
      endAt
      report
      status
      createdAt
      updatedAt
    }
  }
`;
export type LeaveSessionMutationFn = Apollo.MutationFunction<
  LeaveSessionMutation,
  LeaveSessionMutationVariables
>;

/**
 * __useLeaveSessionMutation__
 *
 * To run a mutation, you first call `useLeaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveSessionMutation, { data, loading, error }] = useLeaveSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLeaveSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveSessionMutation,
    LeaveSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveSessionMutation,
    LeaveSessionMutationVariables
  >(LeaveSessionDocument, options);
}
export type LeaveSessionMutationHookResult = ReturnType<
  typeof useLeaveSessionMutation
>;
export type LeaveSessionMutationResult =
  Apollo.MutationResult<LeaveSessionMutation>;
export type LeaveSessionMutationOptions = Apollo.BaseMutationOptions<
  LeaveSessionMutation,
  LeaveSessionMutationVariables
>;
export const ToggleSessionStatusAsInstructorDocument = gql`
  mutation ToggleSessionStatusAsInstructor(
    $sessionId: ID!
    $status: SessionStatus!
  ) {
    toggleSessionStatusAsInstructor(sessionId: $sessionId, status: $status) {
      id
      endAt
      status
    }
  }
`;
export type ToggleSessionStatusAsInstructorMutationFn = Apollo.MutationFunction<
  ToggleSessionStatusAsInstructorMutation,
  ToggleSessionStatusAsInstructorMutationVariables
>;

/**
 * __useToggleSessionStatusAsInstructorMutation__
 *
 * To run a mutation, you first call `useToggleSessionStatusAsInstructorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSessionStatusAsInstructorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSessionStatusAsInstructorMutation, { data, loading, error }] = useToggleSessionStatusAsInstructorMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleSessionStatusAsInstructorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleSessionStatusAsInstructorMutation,
    ToggleSessionStatusAsInstructorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleSessionStatusAsInstructorMutation,
    ToggleSessionStatusAsInstructorMutationVariables
  >(ToggleSessionStatusAsInstructorDocument, options);
}
export type ToggleSessionStatusAsInstructorMutationHookResult = ReturnType<
  typeof useToggleSessionStatusAsInstructorMutation
>;
export type ToggleSessionStatusAsInstructorMutationResult =
  Apollo.MutationResult<ToggleSessionStatusAsInstructorMutation>;
export type ToggleSessionStatusAsInstructorMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleSessionStatusAsInstructorMutation,
    ToggleSessionStatusAsInstructorMutationVariables
  >;
export const ToggleInterpreterNeededDocument = gql`
  mutation ToggleInterpreterNeeded(
    $sessionId: ID!
    $needInterpreter: Boolean!
  ) {
    toggleInterpreterNeeded(
      sessionId: $sessionId
      needInterpreter: $needInterpreter
    ) {
      id
      needInterpreter
      status
      createdAt
      updatedAt
    }
  }
`;
export type ToggleInterpreterNeededMutationFn = Apollo.MutationFunction<
  ToggleInterpreterNeededMutation,
  ToggleInterpreterNeededMutationVariables
>;

/**
 * __useToggleInterpreterNeededMutation__
 *
 * To run a mutation, you first call `useToggleInterpreterNeededMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleInterpreterNeededMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleInterpreterNeededMutation, { data, loading, error }] = useToggleInterpreterNeededMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      needInterpreter: // value for 'needInterpreter'
 *   },
 * });
 */
export function useToggleInterpreterNeededMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleInterpreterNeededMutation,
    ToggleInterpreterNeededMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleInterpreterNeededMutation,
    ToggleInterpreterNeededMutationVariables
  >(ToggleInterpreterNeededDocument, options);
}
export type ToggleInterpreterNeededMutationHookResult = ReturnType<
  typeof useToggleInterpreterNeededMutation
>;
export type ToggleInterpreterNeededMutationResult =
  Apollo.MutationResult<ToggleInterpreterNeededMutation>;
export type ToggleInterpreterNeededMutationOptions = Apollo.BaseMutationOptions<
  ToggleInterpreterNeededMutation,
  ToggleInterpreterNeededMutationVariables
>;
export const SessionUpdateDocument = gql`
  subscription SessionUpdate($sessionId: ID!) {
    sessionUpdate(sessionId: $sessionId) {
      id
      requester {
        id
        name
        usuableTickets
      }
      instructor {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
        }
      }
      needInterpreter
      jwt
      startAt
      endAt
      report
      status
      users {
        id
        user {
          id
          name
          avatar {
            id
            uri
          }
          instructor {
            id
            job
            experiences {
              id
              place
              job
            }
          }
          interpreter {
            id
            experiences {
              id
              place
              job
            }
          }
        }
        joinAt
        leaveAt
        active
      }
      extensions {
        id
        session {
          id
        }
        userPass {
          id
        }
        userSubscription {
          id
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useSessionUpdateSubscription__
 *
 * To run a query within a React component, call `useSessionUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUpdateSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SessionUpdateSubscription,
    SessionUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SessionUpdateSubscription,
    SessionUpdateSubscriptionVariables
  >(SessionUpdateDocument, options);
}
export type SessionUpdateSubscriptionHookResult = ReturnType<
  typeof useSessionUpdateSubscription
>;
export type SessionUpdateSubscriptionResult =
  Apollo.SubscriptionResult<SessionUpdateSubscription>;
export const GetSessionMessagesDocument = gql`
  query GetSessionMessages($sessionId: ID!, $after: String, $first: Int) {
    getSessionMessages(sessionId: $sessionId, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        session {
          id
          status
        }
        user {
          id
          name
        }
        body
        media {
          id
          uri
        }
        createdAt
      }
    }
  }
`;

/**
 * __useGetSessionMessagesQuery__
 *
 * To run a query within a React component, call `useGetSessionMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionMessagesQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetSessionMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >(GetSessionMessagesDocument, options);
}
export function useGetSessionMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >(GetSessionMessagesDocument, options);
}
export function useGetSessionMessagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSessionMessagesQuery,
    GetSessionMessagesQueryVariables
  >(GetSessionMessagesDocument, options);
}
export type GetSessionMessagesQueryHookResult = ReturnType<
  typeof useGetSessionMessagesQuery
>;
export type GetSessionMessagesLazyQueryHookResult = ReturnType<
  typeof useGetSessionMessagesLazyQuery
>;
export type GetSessionMessagesSuspenseQueryHookResult = ReturnType<
  typeof useGetSessionMessagesSuspenseQuery
>;
export type GetSessionMessagesQueryResult = Apollo.QueryResult<
  GetSessionMessagesQuery,
  GetSessionMessagesQueryVariables
>;
export const NewMessageDocument = gql`
  subscription NewMessage($sessionId: ID!) {
    newMessage(sessionId: $sessionId) {
      id
      session {
        id
        status
      }
      user {
        id
        name
      }
      body
      media {
        id
        uri
      }
      createdAt
    }
  }
`;

/**
 * __useNewMessageSubscription__
 *
 * To run a query within a React component, call `useNewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useNewMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewMessageSubscription,
    NewMessageSubscriptionVariables
  >(NewMessageDocument, options);
}
export type NewMessageSubscriptionHookResult = ReturnType<
  typeof useNewMessageSubscription
>;
export type NewMessageSubscriptionResult =
  Apollo.SubscriptionResult<NewMessageSubscription>;
export const SendMessageDocument = gql`
  mutation SendMessage(
    $sessionId: ID!
    $body: String
    $attachment: MediaInput
  ) {
    sendMessage(sessionId: $sessionId, body: $body, attachment: $attachment) {
      id
      session {
        id
        status
      }
      user {
        id
        name
      }
      body
      media {
        id
        uri
      }
      createdAt
    }
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      body: // value for 'body'
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const ExtendSessionDocument = gql`
  mutation ExtendSession(
    $sessionId: ID!
    $userPassId: ID
    $subscriptionId: ID
  ) {
    extendSession(
      sessionId: $sessionId
      userPassId: $userPassId
      subscriptionId: $subscriptionId
    ) {
      id
      session {
        id
        status
      }
      userPass {
        id
      }
      userSubscription {
        id
      }
      createdAt
    }
  }
`;
export type ExtendSessionMutationFn = Apollo.MutationFunction<
  ExtendSessionMutation,
  ExtendSessionMutationVariables
>;

/**
 * __useExtendSessionMutation__
 *
 * To run a mutation, you first call `useExtendSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendSessionMutation, { data, loading, error }] = useExtendSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      userPassId: // value for 'userPassId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useExtendSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExtendSessionMutation,
    ExtendSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExtendSessionMutation,
    ExtendSessionMutationVariables
  >(ExtendSessionDocument, options);
}
export type ExtendSessionMutationHookResult = ReturnType<
  typeof useExtendSessionMutation
>;
export type ExtendSessionMutationResult =
  Apollo.MutationResult<ExtendSessionMutation>;
export type ExtendSessionMutationOptions = Apollo.BaseMutationOptions<
  ExtendSessionMutation,
  ExtendSessionMutationVariables
>;
export const AddSubscriptionDocument = gql`
  mutation AddSubscription(
    $subscriptionPlanId: ID!
    $billingId: ID!
    $finalPrice: Float!
  ) {
    addSubscription(
      subscriptionPlanId: $subscriptionPlanId
      billingId: $billingId
      finalPrice: $finalPrice
    ) {
      id
      user {
        id
      }
      subscriptionPlan {
        id
      }
      billing {
        id
      }
      from
      to
      nextPayAt
      status
      createdAt
      updatedAt
    }
  }
`;
export type AddSubscriptionMutationFn = Apollo.MutationFunction<
  AddSubscriptionMutation,
  AddSubscriptionMutationVariables
>;

/**
 * __useAddSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionMutation, { data, loading, error }] = useAddSubscriptionMutation({
 *   variables: {
 *      subscriptionPlanId: // value for 'subscriptionPlanId'
 *      billingId: // value for 'billingId'
 *      finalPrice: // value for 'finalPrice'
 *   },
 * });
 */
export function useAddSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSubscriptionMutation,
    AddSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSubscriptionMutation,
    AddSubscriptionMutationVariables
  >(AddSubscriptionDocument, options);
}
export type AddSubscriptionMutationHookResult = ReturnType<
  typeof useAddSubscriptionMutation
>;
export type AddSubscriptionMutationResult =
  Apollo.MutationResult<AddSubscriptionMutation>;
export type AddSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  AddSubscriptionMutation,
  AddSubscriptionMutationVariables
>;
export const RequestCancelSubscriptionDocument = gql`
  mutation RequestCancelSubscription($subscriptionId: ID!) {
    requestCancelSubscription(subscriptionId: $subscriptionId) {
      id
      user {
        id
      }
      subscriptionPlan {
        id
      }
      billing {
        id
      }
      from
      to
      nextPayAt
      status
      createdAt
      updatedAt
    }
  }
`;
export type RequestCancelSubscriptionMutationFn = Apollo.MutationFunction<
  RequestCancelSubscriptionMutation,
  RequestCancelSubscriptionMutationVariables
>;

/**
 * __useRequestCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useRequestCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCancelSubscriptionMutation, { data, loading, error }] = useRequestCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useRequestCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCancelSubscriptionMutation,
    RequestCancelSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestCancelSubscriptionMutation,
    RequestCancelSubscriptionMutationVariables
  >(RequestCancelSubscriptionDocument, options);
}
export type RequestCancelSubscriptionMutationHookResult = ReturnType<
  typeof useRequestCancelSubscriptionMutation
>;
export type RequestCancelSubscriptionMutationResult =
  Apollo.MutationResult<RequestCancelSubscriptionMutation>;
export type RequestCancelSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    RequestCancelSubscriptionMutation,
    RequestCancelSubscriptionMutationVariables
  >;
export const RevokeSubscriptionDocument = gql`
  mutation RevokeSubscription($subscriptionId: ID!, $newBillingId: ID!) {
    revokeSubscription(
      subscriptionId: $subscriptionId
      newBillingId: $newBillingId
    ) {
      id
      user {
        id
      }
      subscriptionPlan {
        id
      }
      billing {
        id
      }
      from
      to
      nextPayAt
      status
      createdAt
      updatedAt
    }
  }
`;
export type RevokeSubscriptionMutationFn = Apollo.MutationFunction<
  RevokeSubscriptionMutation,
  RevokeSubscriptionMutationVariables
>;

/**
 * __useRevokeSubscriptionMutation__
 *
 * To run a mutation, you first call `useRevokeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeSubscriptionMutation, { data, loading, error }] = useRevokeSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      newBillingId: // value for 'newBillingId'
 *   },
 * });
 */
export function useRevokeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeSubscriptionMutation,
    RevokeSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeSubscriptionMutation,
    RevokeSubscriptionMutationVariables
  >(RevokeSubscriptionDocument, options);
}
export type RevokeSubscriptionMutationHookResult = ReturnType<
  typeof useRevokeSubscriptionMutation
>;
export type RevokeSubscriptionMutationResult =
  Apollo.MutationResult<RevokeSubscriptionMutation>;
export type RevokeSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  RevokeSubscriptionMutation,
  RevokeSubscriptionMutationVariables
>;
export const GetMySubscriptionsDocument = gql`
  query GetMySubscriptions {
    getMySubscriptions {
      id
      user {
        id
      }
      subscriptionPlan {
        id
        name
        tickets
        finalPrice
      }
      billing {
        id
      }
      from
      to
      nextPayAt
      status
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMySubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetMySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMySubscriptionsQuery,
    GetMySubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMySubscriptionsQuery,
    GetMySubscriptionsQueryVariables
  >(GetMySubscriptionsDocument, options);
}
export function useGetMySubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySubscriptionsQuery,
    GetMySubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMySubscriptionsQuery,
    GetMySubscriptionsQueryVariables
  >(GetMySubscriptionsDocument, options);
}
export function useGetMySubscriptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMySubscriptionsQuery,
    GetMySubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMySubscriptionsQuery,
    GetMySubscriptionsQueryVariables
  >(GetMySubscriptionsDocument, options);
}
export type GetMySubscriptionsQueryHookResult = ReturnType<
  typeof useGetMySubscriptionsQuery
>;
export type GetMySubscriptionsLazyQueryHookResult = ReturnType<
  typeof useGetMySubscriptionsLazyQuery
>;
export type GetMySubscriptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMySubscriptionsSuspenseQuery
>;
export type GetMySubscriptionsQueryResult = Apollo.QueryResult<
  GetMySubscriptionsQuery,
  GetMySubscriptionsQueryVariables
>;
export const ChangeBillingKeyForSubscriptionDocument = gql`
  mutation ChangeBillingKeyForSubscription(
    $newBillingId: ID!
    $subscriptionId: ID
  ) {
    changeBillingKeyForSubscription(
      newBillingId: $newBillingId
      subscriptionId: $subscriptionId
    ) {
      id
      user {
        id
      }
      subscriptionPlan {
        id
      }
      billing {
        id
      }
      from
      to
      nextPayAt
      status
      createdAt
      updatedAt
    }
  }
`;
export type ChangeBillingKeyForSubscriptionMutationFn = Apollo.MutationFunction<
  ChangeBillingKeyForSubscriptionMutation,
  ChangeBillingKeyForSubscriptionMutationVariables
>;

/**
 * __useChangeBillingKeyForSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeBillingKeyForSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBillingKeyForSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBillingKeyForSubscriptionMutation, { data, loading, error }] = useChangeBillingKeyForSubscriptionMutation({
 *   variables: {
 *      newBillingId: // value for 'newBillingId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useChangeBillingKeyForSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeBillingKeyForSubscriptionMutation,
    ChangeBillingKeyForSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeBillingKeyForSubscriptionMutation,
    ChangeBillingKeyForSubscriptionMutationVariables
  >(ChangeBillingKeyForSubscriptionDocument, options);
}
export type ChangeBillingKeyForSubscriptionMutationHookResult = ReturnType<
  typeof useChangeBillingKeyForSubscriptionMutation
>;
export type ChangeBillingKeyForSubscriptionMutationResult =
  Apollo.MutationResult<ChangeBillingKeyForSubscriptionMutation>;
export type ChangeBillingKeyForSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeBillingKeyForSubscriptionMutation,
    ChangeBillingKeyForSubscriptionMutationVariables
  >;
export const GetMyUserProfileDocument = gql`
  query GetMyUserProfile {
    getMyUserProfile {
      id
      type
      email
      name
      gender
      phoneCode
      phone
      dateOfBirth
      provider
      avatar {
        id
        uri
      }
      resignReason
      nationality {
        id
        name
      }
      profile {
        id
      }
      instructor {
        id
      }
      interpreter {
        id
      }
      languageOptions {
        id
        name
      }
      isSubscriber
      usuableTickets
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

/**
 * __useGetMyUserProfileQuery__
 *
 * To run a query within a React component, call `useGetMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyUserProfileQuery, GetMyUserProfileQueryVariables>(
    GetMyUserProfileDocument,
    options
  );
}
export function useGetMyUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >(GetMyUserProfileDocument, options);
}
export function useGetMyUserProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyUserProfileQuery,
    GetMyUserProfileQueryVariables
  >(GetMyUserProfileDocument, options);
}
export type GetMyUserProfileQueryHookResult = ReturnType<
  typeof useGetMyUserProfileQuery
>;
export type GetMyUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyUserProfileLazyQuery
>;
export type GetMyUserProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetMyUserProfileSuspenseQuery
>;
export type GetMyUserProfileQueryResult = Apollo.QueryResult<
  GetMyUserProfileQuery,
  GetMyUserProfileQueryVariables
>;
export const GetMyProfileDocument = gql`
  query GetMyProfile {
    getMyProfile {
      id
      user {
        id
      }
      weight
      height
      bloodType
      occupation
      companyName
      emergencyCallerName
      emergencyCallerNationality {
        id
        name
      }
      emergencyCallerPhoneCode
      emergencyCallerPhone
      emergencyCallerBloodType
      emergencyCallerRelation
      emergencyCallerAddress
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyProfileQuery,
    GetMyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options
  );
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyProfileQuery,
    GetMyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options
  );
}
export function useGetMyProfileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyProfileQuery,
    GetMyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options
  );
}
export type GetMyProfileQueryHookResult = ReturnType<
  typeof useGetMyProfileQuery
>;
export type GetMyProfileLazyQueryHookResult = ReturnType<
  typeof useGetMyProfileLazyQuery
>;
export type GetMyProfileSuspenseQueryHookResult = ReturnType<
  typeof useGetMyProfileSuspenseQuery
>;
export type GetMyProfileQueryResult = Apollo.QueryResult<
  GetMyProfileQuery,
  GetMyProfileQueryVariables
>;
export const GetMyBillingsDocument = gql`
  query GetMyBillings {
    getMyBillings {
      id
      user {
        id
      }
      cardName
      cardNumber
      pgProvider
      customerUid
      active
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetMyBillingsQuery__
 *
 * To run a query within a React component, call `useGetMyBillingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBillingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBillingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBillingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyBillingsQuery,
    GetMyBillingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyBillingsQuery, GetMyBillingsQueryVariables>(
    GetMyBillingsDocument,
    options
  );
}
export function useGetMyBillingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyBillingsQuery,
    GetMyBillingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyBillingsQuery, GetMyBillingsQueryVariables>(
    GetMyBillingsDocument,
    options
  );
}
export function useGetMyBillingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyBillingsQuery,
    GetMyBillingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyBillingsQuery,
    GetMyBillingsQueryVariables
  >(GetMyBillingsDocument, options);
}
export type GetMyBillingsQueryHookResult = ReturnType<
  typeof useGetMyBillingsQuery
>;
export type GetMyBillingsLazyQueryHookResult = ReturnType<
  typeof useGetMyBillingsLazyQuery
>;
export type GetMyBillingsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyBillingsSuspenseQuery
>;
export type GetMyBillingsQueryResult = Apollo.QueryResult<
  GetMyBillingsQuery,
  GetMyBillingsQueryVariables
>;
export const ChangeActiveBillingDocument = gql`
  mutation ChangeActiveBilling($billingId: ID!) {
    changeActiveBilling(billingId: $billingId) {
      id
      user {
        id
      }
      cardName
      cardNumber
      pgProvider
      customerUid
      active
      createdAt
      updatedAt
    }
  }
`;
export type ChangeActiveBillingMutationFn = Apollo.MutationFunction<
  ChangeActiveBillingMutation,
  ChangeActiveBillingMutationVariables
>;

/**
 * __useChangeActiveBillingMutation__
 *
 * To run a mutation, you first call `useChangeActiveBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeActiveBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeActiveBillingMutation, { data, loading, error }] = useChangeActiveBillingMutation({
 *   variables: {
 *      billingId: // value for 'billingId'
 *   },
 * });
 */
export function useChangeActiveBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeActiveBillingMutation,
    ChangeActiveBillingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeActiveBillingMutation,
    ChangeActiveBillingMutationVariables
  >(ChangeActiveBillingDocument, options);
}
export type ChangeActiveBillingMutationHookResult = ReturnType<
  typeof useChangeActiveBillingMutation
>;
export type ChangeActiveBillingMutationResult =
  Apollo.MutationResult<ChangeActiveBillingMutation>;
export type ChangeActiveBillingMutationOptions = Apollo.BaseMutationOptions<
  ChangeActiveBillingMutation,
  ChangeActiveBillingMutationVariables
>;
export const GetUserPassDocument = gql`
  query GetUserPass($userPassId: ID!) {
    getUserPass(userPassId: $userPassId) {
      id
      purchaser {
        id
      }
      user {
        id
      }
      pass {
        id
      }
      code
      status
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetUserPassQuery__
 *
 * To run a query within a React component, call `useGetUserPassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPassQuery({
 *   variables: {
 *      userPassId: // value for 'userPassId'
 *   },
 * });
 */
export function useGetUserPassQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPassQuery,
    GetUserPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPassQuery, GetUserPassQueryVariables>(
    GetUserPassDocument,
    options
  );
}
export function useGetUserPassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPassQuery,
    GetUserPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPassQuery, GetUserPassQueryVariables>(
    GetUserPassDocument,
    options
  );
}
export function useGetUserPassSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUserPassQuery,
    GetUserPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserPassQuery, GetUserPassQueryVariables>(
    GetUserPassDocument,
    options
  );
}
export type GetUserPassQueryHookResult = ReturnType<typeof useGetUserPassQuery>;
export type GetUserPassLazyQueryHookResult = ReturnType<
  typeof useGetUserPassLazyQuery
>;
export type GetUserPassSuspenseQueryHookResult = ReturnType<
  typeof useGetUserPassSuspenseQuery
>;
export type GetUserPassQueryResult = Apollo.QueryResult<
  GetUserPassQuery,
  GetUserPassQueryVariables
>;
export const UpdateUserBillingDocument = gql`
  mutation UpdateUserBilling($billingInput: BillingInput!) {
    updateUserBilling(billingInput: $billingInput) {
      id
      user {
        id
      }
      cardName
      cardNumber
      pgProvider
      customerUid
      active
      createdAt
      updatedAt
    }
  }
`;
export type UpdateUserBillingMutationFn = Apollo.MutationFunction<
  UpdateUserBillingMutation,
  UpdateUserBillingMutationVariables
>;

/**
 * __useUpdateUserBillingMutation__
 *
 * To run a mutation, you first call `useUpdateUserBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBillingMutation, { data, loading, error }] = useUpdateUserBillingMutation({
 *   variables: {
 *      billingInput: // value for 'billingInput'
 *   },
 * });
 */
export function useUpdateUserBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserBillingMutation,
    UpdateUserBillingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserBillingMutation,
    UpdateUserBillingMutationVariables
  >(UpdateUserBillingDocument, options);
}
export type UpdateUserBillingMutationHookResult = ReturnType<
  typeof useUpdateUserBillingMutation
>;
export type UpdateUserBillingMutationResult =
  Apollo.MutationResult<UpdateUserBillingMutation>;
export type UpdateUserBillingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserBillingMutation,
  UpdateUserBillingMutationVariables
>;
export const UpdateMyUserProfileDocument = gql`
  mutation UpdateMyUserProfile($userInput: UserInput!) {
    updateMyUserProfile(userInput: $userInput) {
      id
      type
      email
      name
      gender
      phoneCode
      phone
      dateOfBirth
      provider
      avatar {
        id
        uri
      }
      resignReason
      nationality {
        id
        name
      }
      profile {
        id
      }
      instructor {
        id
      }
      interpreter {
        id
      }
      languageOptions {
        id
        name
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export type UpdateMyUserProfileMutationFn = Apollo.MutationFunction<
  UpdateMyUserProfileMutation,
  UpdateMyUserProfileMutationVariables
>;

/**
 * __useUpdateMyUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyUserProfileMutation, { data, loading, error }] = useUpdateMyUserProfileMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateMyUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyUserProfileMutation,
    UpdateMyUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyUserProfileMutation,
    UpdateMyUserProfileMutationVariables
  >(UpdateMyUserProfileDocument, options);
}
export type UpdateMyUserProfileMutationHookResult = ReturnType<
  typeof useUpdateMyUserProfileMutation
>;
export type UpdateMyUserProfileMutationResult =
  Apollo.MutationResult<UpdateMyUserProfileMutation>;
export type UpdateMyUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyUserProfileMutation,
  UpdateMyUserProfileMutationVariables
>;
export const UpdateMyProfileDocument = gql`
  mutation UpdateMyProfile($profileInput: ProfileInput!) {
    updateMyProfile(profileInput: $profileInput) {
      id
      user {
        id
      }
      weight
      height
      bloodType
      occupation
      companyName
      emergencyCallerName
      emergencyCallerNationality {
        id
        name
      }
      emergencyCallerPhoneCode
      emergencyCallerPhone
      emergencyCallerBloodType
      emergencyCallerRelation
      emergencyCallerAddress
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMyProfileMutationFn = Apollo.MutationFunction<
  UpdateMyProfileMutation,
  UpdateMyProfileMutationVariables
>;

/**
 * __useUpdateMyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProfileMutation, { data, loading, error }] = useUpdateMyProfileMutation({
 *   variables: {
 *      profileInput: // value for 'profileInput'
 *   },
 * });
 */
export function useUpdateMyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyProfileMutation,
    UpdateMyProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyProfileMutation,
    UpdateMyProfileMutationVariables
  >(UpdateMyProfileDocument, options);
}
export type UpdateMyProfileMutationHookResult = ReturnType<
  typeof useUpdateMyProfileMutation
>;
export type UpdateMyProfileMutationResult =
  Apollo.MutationResult<UpdateMyProfileMutation>;
export type UpdateMyProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyProfileMutation,
  UpdateMyProfileMutationVariables
>;
export const PurchaseUserPassByBillingDocument = gql`
  mutation PurchaseUserPassByBilling(
    $passId: ID!
    $billingId: ID!
    $userPassId: ID!
  ) {
    purchaseUserPassByBilling(
      passId: $passId
      billingId: $billingId
      userPassId: $userPassId
    )
  }
`;
export type PurchaseUserPassByBillingMutationFn = Apollo.MutationFunction<
  PurchaseUserPassByBillingMutation,
  PurchaseUserPassByBillingMutationVariables
>;

/**
 * __usePurchaseUserPassByBillingMutation__
 *
 * To run a mutation, you first call `usePurchaseUserPassByBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseUserPassByBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseUserPassByBillingMutation, { data, loading, error }] = usePurchaseUserPassByBillingMutation({
 *   variables: {
 *      passId: // value for 'passId'
 *      billingId: // value for 'billingId'
 *      userPassId: // value for 'userPassId'
 *   },
 * });
 */
export function usePurchaseUserPassByBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseUserPassByBillingMutation,
    PurchaseUserPassByBillingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PurchaseUserPassByBillingMutation,
    PurchaseUserPassByBillingMutationVariables
  >(PurchaseUserPassByBillingDocument, options);
}
export type PurchaseUserPassByBillingMutationHookResult = ReturnType<
  typeof usePurchaseUserPassByBillingMutation
>;
export type PurchaseUserPassByBillingMutationResult =
  Apollo.MutationResult<PurchaseUserPassByBillingMutation>;
export type PurchaseUserPassByBillingMutationOptions =
  Apollo.BaseMutationOptions<
    PurchaseUserPassByBillingMutation,
    PurchaseUserPassByBillingMutationVariables
  >;
