import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "./Avatar";
import Button from "./Button";
import Flex from "./Flex";
import Input from "./Input";
import InputLabel from "./InputLabel";
import LanguageOptionInput from "./LanguageOptionInput";
import Loading from "./Loading";
import NationalityInput from "./NationalityInput";
import usePersonalProfileForm from "./PersonalProfileForm.hook";
import PhoneInput from "./PhoneInput";
import Text from "./Text";
import { Gender } from "../../../lib/apollo/graphql/generated";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../provider/ScreenWidthProvider";

interface PersonalProfileFormProps {
  isCreating?: boolean;
}

function PersonalProfileForm({ isCreating = false }: PersonalProfileFormProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenWidthContext();

  const {
    refs: { avatarInputRef },
    models: { state, loading, updateLoading, data },
    operations: {
      onInputChange,
      onAvatarChange,
      onGenderSelect,
      onNationalityChange,
      onLanguageOptionSelect,
      onPhoneCodeChange,
      isSubmitDisabled,
      onSubmit,
      onSignOut,
    },
  } = usePersonalProfileForm(isCreating);

  const {
    avatarState,
    name,
    phoneCode,
    phone,
    gender,
    dateOfBirth,
    nationality,
    languageOptionIds,
  } = state;

  const memoizedAvatarInput = useMemo(
    () => (
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={`${theme.spacing[24]}px 0px`}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={291}
          height={329}
          boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.12)"
          gap={theme.spacing[4]}
        >
          <Avatar
            size={200}
            uri={
              avatarState ? URL.createObjectURL(avatarState) : data?.avatar?.uri
            }
            initial={name?.slice(0, 1)}
            onClick={() => avatarInputRef?.current?.click()}
          />
          <Text typography="body1Bold">{name}</Text>
        </Flex>
        <input
          ref={avatarInputRef}
          type="file"
          accept="image/*"
          onChange={onAvatarChange}
        />
      </Flex>
    ),
    [avatarState, data?.avatar?.uri, name, avatarInputRef?.current]
  );

  const memoizedEmailInput = useMemo(
    () => (
      <Input label={t("common.email")} value={data?.email!} disabled={true} />
    ),
    [data?.email]
  );

  const memoizedNameInput = useMemo(
    () => (
      <Input
        label={t("common.name")}
        placeholder={t("common.namePlaceholder")}
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "name")
        }
      />
    ),
    [name]
  );

  const memoizedGenderSelector = useMemo(
    () => (
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        gap={theme.spacing[8]}
      >
        <InputLabel label={t("common.gender")} />
        <Flex alignItems="center" gap={theme.spacing[12]}>
          <Button
            round={false}
            text={t("common.male")}
            size="sm"
            variant={gender === Gender.Male ? "contained" : "outline"}
            fontColor={gender === Gender.Male ? "neutralWhite" : "neutral700"}
            borderColor={gender === Gender.Male ? "neutralWhite" : "neutral700"}
            bgColor={gender === Gender.Male ? "primary1" : "neutralWhite"}
            onClick={() => onGenderSelect(Gender.Male)}
          />
          <Button
            round={false}
            text={t("common.female")}
            size="sm"
            variant={gender === Gender.Female ? "contained" : "outline"}
            fontColor={gender === Gender.Female ? "neutralWhite" : "neutral700"}
            borderColor={
              gender === Gender.Female ? "neutralWhite" : "neutral700"
            }
            bgColor={gender === Gender.Female ? "primary1" : "neutralWhite"}
            onClick={() => onGenderSelect(Gender.Female)}
          />
        </Flex>
      </Flex>
    ),
    [gender]
  );

  const memoizedPhoneInput = useMemo(
    () => (
      <PhoneInput
        phoneCode={phoneCode}
        label={t("common.phone")}
        inputMode="tel"
        placeholder={t("common.phonePlaceholder")}
        value={phone}
        maxLength={11}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "phone")
        }
        onPhoneCodeChange={onPhoneCodeChange}
      />
    ),
    [phone, phoneCode]
  );

  const memoizedDateOfBirthInput = useMemo(
    () => (
      <Input
        label={t("common.dateOfBirth")}
        type="date"
        max={dayjs().format("YYYY-MM-DD")}
        value={dateOfBirth}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "dateOfBirth")
        }
      />
    ),
    [dateOfBirth]
  );

  const memoizedNationalityInput = useMemo(
    () => (
      <NationalityInput
        label={t("common.nationality")}
        value={nationality}
        onChange={onNationalityChange}
      />
    ),
    [nationality]
  );

  const memoizedLanguageOptionInput = useMemo(
    () => (
      <LanguageOptionInput
        label={t("common.languageOptionTitle")}
        selectedOptionIds={languageOptionIds}
        onOptionSelect={onLanguageOptionSelect}
      />
    ),
    [languageOptionIds]
  );

  if (loading) return <Loading />;

  return (
    <Flex flexDirection="column" width="100%" gap={theme.spacing[24]}>
      {!isCreating && memoizedAvatarInput}
      {!isCreating && (
        <div
          style={{
            width: "100%",
            maxWidth: 720,
            height: 2,
            backgroundColor: theme.color.neutral800,
            margin: "0px auto",
          }}
        />
      )}
      {!isCreating && memoizedEmailInput}
      {!isCreating && (
        <div
          style={{
            width: "100%",
            maxWidth: 720,
            height: 2,
            backgroundColor: theme.color.neutral800,
            margin: "0px auto",
          }}
        />
      )}

      {memoizedNameInput}
      {memoizedGenderSelector}
      {memoizedPhoneInput}
      {memoizedDateOfBirthInput}
      {memoizedNationalityInput}
      {memoizedLanguageOptionInput}

      <Flex
        alignItems="center"
        justifyContent="center"
        marginTop={isMobile ? 16 : isCreating ? 48 : 24}
        gap={theme.spacing[64]}
        marginBottom={isCreating ? 0 : theme.spacing[24]}
      >
        <Button
          disabled={isSubmitDisabled()}
          text={
            updateLoading
              ? t("common.saving")
              : isCreating
              ? t("common.next")
              : t("common.save")
          }
          size={isMobile ? "md" : "lg"}
          onClick={onSubmit}
        />
      </Flex>
      {!isCreating && (
        <Flex alignItems="center" justifyContent="flex-end">
          <div onClick={onSignOut}>
            <Text
              typography="label2"
              color={theme.color.neutral800}
              cursor="pointer"
            >
              {t("common.signOut")}
            </Text>
          </div>
        </Flex>
      )}
    </Flex>
  );
}

export default PersonalProfileForm;
