import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { firebaseSignUp } from "../../../../../lib/firebase";
import { SCREENS } from "../../../../../lib/route/utils/router";

function useSignUpStep1Container() {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
  });

  const [signingUp, setSigningUp] = useState(false);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFocus(key: keyof typeof state) {
    if (!!state[key]) {
      setState((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  }

  function onBlur(key: keyof typeof state) {
    if (key === "emailError" && !validateEmail()) {
      setState((prev) => ({
        ...prev,
        emailError: t("error.emailError"),
      }));
    }

    if (key === "passwordError" && !validatePasswordLength(key)) {
      setState((prev) => ({
        ...prev,
        passwordError: t("error.passwordLengthError"),
      }));
    }

    if (key === "confirmPasswordError" && !validatePasswordLength(key)) {
      setState((prev) => ({
        ...prev,
        confirmPasswordError: t("error.passwordLengthError"),
      }));
    }

    if (key === "confirmPasswordError" && !validateConfirmPassword()) {
      setState((prev) => ({
        ...prev,
        confirmPasswordError: t("error.confirmPasswordError"),
      }));
    }
  }

  function validateEmail() {
    return validator.isEmail(state?.email);
  }

  function validatePasswordLength(type: string) {
    return type === "passwordError"
      ? !(state?.password?.length < 6)
      : !(state?.confirmPassword?.length < 6);
  }

  function validateConfirmPassword() {
    return state?.confirmPassword === state?.password;
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = e;

    if (key === "Enter") {
      const isValidEmail = validateEmail();
      const isValidPassword = validatePasswordLength("passwordError");
      const isValidConfirmPassword = validatePasswordLength(
        "confirmPasswordError"
      );
      const isConfirmPasswordSameAsPassword = validateConfirmPassword();

      if (
        !!isValidEmail &&
        !!isValidPassword &&
        !!isValidConfirmPassword &&
        !!isConfirmPasswordSameAsPassword
      ) {
        onSubmit();
      }
    }
  }

  function isSubmitDisabled() {
    const { email, emailError, password, passwordError, confirmPasswordError } =
      state;

    return (
      !!emailError || !!passwordError || !!confirmPasswordError || signingUp
    );
  }

  async function onSubmit() {
    setSigningUp(true);

    try {
      const { email, password, confirmPassword } = state;

      if (isSubmitDisabled()) {
        return;
      }

      await firebaseSignUp(email, password);

      navigate(SCREENS.SIGN_UP + "/step2");
    } catch (error: any) {
      if (error?.message?.includes("auth/email-already-in-use")) {
        setState((prev) => ({
          ...prev,
          emailError: t("error.accountExistError"),
        }));
      }
    }

    setSigningUp(false);
  }

  return {
    models: {
      signingUp,
      state,
    },
    operations: {
      onInputChange,
      onFocus,
      onBlur,
      onKeyDown,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useSignUpStep1Container;
